import style from "./style.scss";
import classnames from "classnames";

function AchievementTableItem(props) {
  return (
    <div
      className={classnames(
        style.AchievementTableItemContainer,
        props.serviceName === "BK" && style.AchievementTableItemContainerBK,
        props.serviceName === "NK" && style.AchievementTableItemContainerNK
      )}
    >
      <h4 className={style.AchievementTableItemHeading}>{props.heading}</h4>
      <p className={style.AchievementTableItemContent}>
        <span className={style.AchievementTableItemContentUnitSmall}>
          {props.contentUnitSmall}
        </span>
        {props.contentNumber}
        <span className={style.AchievementTableItemContentUnit}>
          {props.contentUnit}
        </span>
      </p>
    </div>
  );
}

export default AchievementTableItem;
