import { h } from "preact";
import { Router } from "preact-router";

import Header from "./Header";
import Footer from "./Footer";
import ZennichiHeader from "./ZennichiHeader";
import ZennichiFooter from "./ZennichiFooter";

// Code-splitting is automated for `routes` directory
import ItandiBB from "../routes/itandi_bb";
import BBDetail from "../routes/itandi_bb_detail";
import Bukkaku from "../routes/bukkaku";
import DenshiKeiyaku from "../routes/denshi_keiyaku";
import KoushinTaikyo from "../routes/koushin_taikyo";
import NaisoKouji from "../routes/naiso_kouji";
import MoushikomiUketsuke from "../routes/moushikomi_uketsuke";
import NaikenYoyaku from "../routes/naiken_yoyaku";
import RPAOCR from "../routes/rpa_ocr";
import CRM from "../routes/crm/index";
import CRMFunctions from "../routes/crm/functions";
import Error404 from "../routes/404";
import Privacy from "../routes/privacy";
import BaibaiBukkaku from "../routes/baibai_bukkaku";
import OrangeRibbon from "../routes/orangeribbon";
import NaisoKoujiOmakase from "../routes/naiso_kouji_omakase";
import NaisoKoujiOmakaseFlow from "../routes/naiso_kouji_omakase/flow";
import ItandiKanriCloud from "../routes/itandi_kanri_cloud";
// 以下全日用ページ
import ZennichiItandiBB from "../routes/zennichi/itandi_bb";
import ZennichiBukkaku from "../routes/zennichi/bukkaku";
import ZennichiDenshiKeiyaku from "../routes/zennichi/denshi_keiyaku";
import ZennichiKoushinTaikyo from "../routes/zennichi/koushin_taikyo";
import ZennichiNaisoKouji from "../routes/zennichi/naiso_kouji";
import ZennichiMoushikomiUketsuke from "../routes/zennichi/moushikomi_uketsuke";
import ZennichiNaikenYoyaku from "../routes/zennichi/naiken_yoyaku";
import ZennichiCRM from "../routes/zennichi/crm/index";
import ZennichiCRMFunctions from "../routes/zennichi/crm/functions";
import ZennichiPrivacy from "../routes/zennichi/privacy";
import ZennichiOrangeRibbon from "../routes/zennichi/orangeribbon";
import ZennichiNaisoKoujiOmakase from "../routes/zennichi/naiso_kouji_omakase";
import ZennichiNaisoKoujiOmakaseFlow from "../routes/zennichi/naiso_kouji_omakase/flow";
import ZennichiItandiKanriCloud from "../routes/zennichi/itandi_kanri_cloud";
import ZennichiIkkatsuSyukko from "../routes/zennichi/ikkatsu-syukko";

const BBProducts = () => {
  return (
    <div>
      <Header />
      <Router>
        <ItandiBB path="/" />
        <BBDetail path="/bb-detail" />
        <Bukkaku path="/bukkaku" />
        <DenshiKeiyaku path="/denshi-keiyaku" />
        <KoushinTaikyo path="/koushin-taikyo" />
        <NaisoKouji path="/naiso-kouji" />
        <MoushikomiUketsuke path="/moushikomi-uketsuke" />
        <NaikenYoyaku path="/naiken-yoyaku" />
        <RPAOCR path="/rpa-ocr" />
        <CRM path="/crm" />
        <CRMFunctions path="/crm/functions" />
        <BaibaiBukkaku path="/baibai-bukkaku" />
        <OrangeRibbon path="/orangeribbon" />
        <NaisoKoujiOmakase path="/naiso-kouji-omakase" />
        <NaisoKoujiOmakaseFlow path="/naiso-kouji-omakase/flow" />
        <ItandiKanriCloud path="/itandi-kanri-cloud" />
        <Privacy path="/privacy" />
        <Error404 default />
      </Router>
      <Footer />
    </div>
  );
};

const ZennichiProducts = () => {
  return (
    <div>
      <ZennichiHeader />
      <Router>
        <ZennichiItandiBB path="/zennichi/itandi-bb" />
        <ZennichiBukkaku path="/zennichi/bukkaku" />
        <ZennichiDenshiKeiyaku path="/zennichi/denshi-keiyaku" />
        <ZennichiKoushinTaikyo path="/zennichi/koushin-taikyo" />
        <ZennichiNaisoKouji path="/zennichi/naiso-kouji" />
        <ZennichiMoushikomiUketsuke path="/zennichi/moushikomi-uketsuke" />
        <ZennichiNaikenYoyaku path="/zennichi/naiken-yoyaku" />
        <ZennichiCRM path="/zennichi/crm" />
        <ZennichiCRMFunctions path="/zennichi/crm/functions" />
        <ZennichiOrangeRibbon path="/zennichi/orangeribbon" />
        <ZennichiNaisoKoujiOmakase path="/zennichi/naiso-kouji-omakase" />
        <ZennichiNaisoKoujiOmakaseFlow path="/zennichi/naiso-kouji-omakase/flow" />
        <ZennichiItandiKanriCloud path="/zennichi/itandi-kanri-cloud" />
        <ZennichiIkkatsuSyukko path="/zennichi/ikkatsu-syukko" />
        <ZennichiPrivacy path="/zennichi/privacy" />
        <Error404 default />
      </Router>
      <ZennichiFooter />
    </div>
  );
};

const App = () => (
  <div id="app">
    <Router>
      <BBProducts path="/:to*" />
      <ZennichiProducts path="/zennichi/:to*" />
    </Router>
  </div>
);

export default App;
