import { h } from "preact";
import style from "./style.scss";

const ZennichiOrangeRibbon = () => (
  <>
    <main>
      <section className={style.FirstViewSection}>
        <div className={style.FirstViewContainer}>
          <h1 className={style.FirstViewContainerTitle}>
            不動産会社の皆様だからこそ、
            <br />
            救える命があるかもしれません
          </h1>
          <div className={style.FirstViewImage}>
            <img
              className={style.FirstViewImageLogo}
              alt="イタンジ×オレンジリボン運動"
              src="/assets/orange_ribbon/FV-logo.svg"
            />
            <img
              className={style.FirstViewImageLogoTAB}
              alt="イタンジ×オレンジリボン運動"
              src="/assets/orange_ribbon/FV-logo_TAB.svg"
            />
            <img
              className={style.FirstViewImageLogoSP}
              alt="イタンジ×オレンジリボン運動"
              src="/assets/orange_ribbon/FV-logo_SP.svg"
            />
          </div>
        </div>
      </section>
      <section className={style.AboutSection}>
        <h2 className={style.AboutTitle}>
          <div className={style.SectionTitleImage}>
            <img
              className={style.SectionTitleImageItem}
              alt="オレンジリボン運動とは"
              src="/assets/orange_ribbon/Title_AboutSection.svg"
            />
            <img
              className={style.SectionTitleImageItemSP}
              alt="オレンジリボン運動とは"
              src="/assets/orange_ribbon/Title_AboutSection_SP.svg"
            />
          </div>
        </h2>
        <div className={style.AboutWrapper}>
          <p className={style.AboutText}>
            2004年、栃木県小山市で3歳と4歳になる二人の可愛らしい兄弟が何度も何度も父親の友人から暴行を受けていました。その顔を見たコンビニの店長さんが警察に通報したのですが、いったんは保護されながら、周囲の諸機関が適切な措置を取らなかったために、9月11日ガソリンスタンドで再び暴行を受け、車の中でもさんざん暴行を受け、息も絶え絶えの状態で、橋の上から川に投げ込まれて幼い命を奪われるという痛ましい事件が起こりました。
          </p>
          <br />
          <img
            className={style.AboutImage}
            alt="事件イメージ"
            src="/assets/orange_ribbon/AboutSection_image.png"
          />
          <p className={style.AboutText}>
            二度とこのような事件が起こらないようにという願いを込めて、子ども虐待防止を目指して小山市で始まったのが、オレンジリボン運動です。
            <br />
            こういった、子どもが虐待を受け、命を失ってしまうという事件が年間50件を超えています。それだけではありません。虐待により体や心に傷を受けた子どもたちは、その後の就職や家族生活、人間関係上、多くの困難を抱えることも少なくなく、
            なかにはその苦しさの余り自らの命を絶ってしまう子すらいます。
          </p>
          <br />
          <p className={style.AboutText}>
            このような事件が決して起きてはならないという願いを込めて、認定NPO法人児童虐待防止全国ネットワークが、2006年からオレンジリボンの総合窓口を担い、現在その活動は全国に拡がりつつあります。
          </p>
          <br />
          <div className={style.AboutTextSmall}>
            ※
            <a
              native
              className={style.AboutTextSmallLink}
              href="https://www.orangeribbon.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              オレンジリボン公式ホームページ
            </a>
            より
          </div>
        </div>
      </section>
      <section className={style.SupportSection}>
        <h2 className={style.SupportTitle}>
          <div className={style.SectionTitleImage}>
            <img
              className={style.SectionTitleImageItem}
              alt="イタンジはオレンジリボン運動を支援します"
              src="/assets/orange_ribbon/Title_SupportSection.svg"
            />
            <img
              className={style.SectionTitleImageItemSP}
              alt="イタンジはオレンジリボン運動を支援します"
              src="/assets/orange_ribbon/Title_SupportSection_SP.svg"
            />
          </div>
        </h2>
        <div className={style.SupportWrapper}>
          <p className={style.SupportText}>
            厚生労働省の発表によると、全国の児童相談所が2020年度に対応した18歳未満への虐待件数は20万件を超え、過去最多を更新しました。痛ましい事件の多くは、その「保護者」によって「家庭」で起こっています。
            <small className={style.SupportTextSmall}>※</small>
          </p>
          <br />
          <img
            className={style.SupportImage}
            alt="「児童虐待相談対応件数の推移」グラフ"
            src="/assets/orange_ribbon/SupportSection_Graph.png"
          />
          <br />
          <div className={style.SupportTextSmall}>
            ※出典：
            <a
              native
              className={style.SupportTextSmallLink}
              href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/000019801_00004.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              厚生労働省「令和３年度全国児童福祉主管課長・児童相談所長会議資料」
            </a>
            をもとにイタンジ株式会社作成　
          </div>
          <div className={style.SupportTextSmall}>
            ※平成22年度の件数は、東日本大震災の影響により福島県を除いて集計した数値
          </div>
          <br />
          <p className={style.SupportText}>
            不動産会社の皆様は、「近隣住民からの目撃情報」「家賃の滞納」「ごみ屋敷に該当する極端な不衛生」などの子ども虐待のサインに気づける可能性があります。当社が提供する、リアルタイム不動産業者間サイト「ITANDI
            BB（イタンジ
            ビービー）」は、不動産会社の皆様から月間400万PV以上閲覧されるメディアです。当社はこのITANDI
            BBを通じて、オレンジリボン運動を支援してまいります。不動産会社の皆様だからこそ、気づかれる違和感があるかもしれません。多くの子どもたちの救済にご協力をいただけますと幸いです。
          </p>
        </div>
      </section>
      <div className={style.ImageSection} />
      <section className={style.CheckListSection}>
        <h2 className={style.CheckListTitle}>
          <div className={style.SectionTitleImage}>
            <img
              className={style.SectionTitleImageItem}
              alt="虐待に気づくためのチェックリスト"
              src="/assets/orange_ribbon/Title_CheckListSection.svg"
            />
            <img
              className={style.SectionTitleImageItemSP}
              alt="虐待に気づくためのチェックリスト"
              src="/assets/orange_ribbon/Title_CheckListSection_SP.svg"
            />
          </div>
        </h2>
        <p className={style.CheckListTitleText}>
          地域に、住宅に、そして家庭に触れる不動産会社の皆様へ
          <br />
          こんな「違和感」を感じたら通告（連絡）してください。
        </p>
        <div className={style.CheckListTitleTextSmall}>
          ※通告者や相談内容についての秘密は守ります。&nbsp;※子どもの安全を第一に考えます。
        </div>
        <div className={style.CheckListWrapper}>
          <div className={style.CheckListWrapperItems}>
            <h3 className={style.CheckListWrapperItemsHeading}>
              子どもからの「違和感」
            </h3>
            <ul className={style.CheckListWrapperItemsList}>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                不自然な外傷（あざ、打撲、やけどなど）が見られる
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                家の外に閉め出されている
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                衣服や身体が極端に不潔である
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                食事を与えられていない
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                夜遅くまで遊んだり、徘徊したりしている
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                いつも子供の泣き叫ぶ声、叩かれる音が聞こえる
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                極端な栄養障害や発達の遅れが見られる（低身長、低体重、急な体重減少等）
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                季節にそぐわない服装をしている
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                食事に異常な執着を示す
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                ひどく落ち着きがなく乱暴、情緒不安定、過度に緊張し視線が合わない
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                気力がない、表情が乏しく活気がない（無表情）
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                態度が怯えていたり、親や大人の顔色をうかがったり、親を避けようとする
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                家に帰りたくないそぶりがある
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                誰かれなく大人に甘え、警戒心が過度に薄い
              </li>
            </ul>
          </div>
          <div className={style.CheckListWrapperItems}>
            <h3 className={style.CheckListWrapperItemsHeading}>
              親（保護者）からの「違和感」
            </h3>
            <ul className={style.CheckListWrapperItemsList}>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                マンション等の住民から子供の虐待の目撃等の情報がある
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                子育て家庭において支払が長期間滞っているなど生活の困窮が心配される
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                訪問時に、不自然に子供を隠し、追い返そうとする
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                子育て家庭においていわゆる「ごみ屋敷」など著しく不衛生である
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                小さい子供を置いたまま外出している
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                体罰を正当化する
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                子供が怪我や病気をしても医師に見せない、怪我等について不自然な説明をする
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                地域や親族などと交流がなく、孤立している、支援に拒否的である
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                子供の養育に関して拒否的、無関心である
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                年齢不相応な養育（しつけ）を正当化する
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                子供に対して拒否的な発言をする
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                気分の変動が激しく、子供や他人にかんしゃくを爆発させる
              </li>
              <li className={style.CheckListWrapperItemsListItems}>
                <img
                  className={style.CheckListItemsImage}
                  alt="チェックボックス"
                  src="/assets/orange_ribbon/checkbox-fill.svg"
                />
                夜間徘徊などを黙認する
              </li>
            </ul>
          </div>
          <div className={style.CheckListSmall}>
            ※参考：
            <a
              native
              className={style.CheckListSmallLink}
              href="https://www.fukushihoken.metro.tokyo.lg.jp/kodomo/katei/taiseikyouka310125.files/checklist.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              「東京都&nbsp;虐待に気づくためのチェックリスト」
            </a>
          </div>
          <br />
          <p className={style.CheckListText}>
            上記のような違和感に気づいたら、「大袈裟かな？」と思わずにご連絡ください。
            <br />
            皆様の1つのアクションで救える子どもがいるかもしれません。
          </p>
        </div>
      </section>
      <section className={style.TelSection}>
        <div className={style.TelWrapper}>
          <div className={style.TelHeadingWrapper}>
            <h2 className={style.TelHeadingWrapperText}>
              児童相談所虐待対応ダイヤル
            </h2>
            <div className={style.TelHeadingWrapperContent}>
              <img
                className={style.TelHeadingWrapperImage}
                alt="電話マーク"
                src="/assets/orange_ribbon/tel-fill.svg"
              />
              <div className={style.TelHeadingWrapperNumber}>
                <div className={style.TelNumberRuby}>イチハヤク</div>
                <strong className={style.TelNumber}>189</strong>
              </div>
            </div>
          </div>
          <p className={style.TelText}>
            虐待かもと思った時などに、すぐに児童相談所に通告・相談ができる全国共通の電話番号です。
            <br />
            児童相談所虐待対応ダイヤル「189」にかけるとお近くの児童相談所につながります。虐待かどうかの判断は、児童相談所によって行われますので虐待を確認できる証拠などは不要です。通話料はかかりません。24時間つながります。（一部のIP電話はつながりません。）
            <br />
            <span className={style.TelTextStrong}>
              通告・相談は、匿名で行うこともでき、通告・相談をした人、その内容に関する秘密は守られます。
            </span>
          </p>
        </div>
      </section>
      <section className={style.CommentSection}>
        <div className={style.CommentWrapper}>
          <p className={style.CommentText}>
            不動産業務に従事される皆様の日々の業務や生活のなかで、ふと、違和感に気づくことはありませんか？
            <br />
            気づいたとしても、どうすればよいかわからない方が殆どではないでしょうか。
            <br />
            皆様の「気づき」によって、子ども虐待の可能性を発見し、救える命があるかもしれません。
            <br />
            そんな想いをこめて、NPO法人児童虐待防止全国ネットワークの皆様のご協力のもと、本ページを作成いたしました。
            <br />
            地域に、住宅に、そして家庭に触れることのある、皆様だからこそできること。
            <br />
            1人でも多くの子どもが救われることにご協力をいただけますと幸いです。
          </p>
          <p className={style.CommentFrom}>
            イタンジ株式会社
            <br />
            オレンジリボンプロジェクトチーム
          </p>
        </div>
      </section>
    </main>
  </>
);

export default ZennichiOrangeRibbon;
