import { h } from "preact";
import { useState } from "react";
import Match from "preact-router/match";
import style from "./style.scss";
import itandibbLogo from "/assets/logo_itandi_bb.svg";
import classnames from "classnames";

// 管理SaaSまとめ
function SaaSListKanri() {
  return (
    <>
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_BB.svg"
        title="ITANDI BB"
        text="業者間サイト掲載（無料）"
        href="/bb-detail/"
      />
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_BK.svg"
        title="ぶっかくん"
        text="物確電話自動応答"
        href="/bukkaku/"
      />
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_NK.svg"
        title="内見予約くん"
        text="内見受付の自動化"
        href="/naiken-yoyaku/"
      />
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_MU.svg"
        title="申込受付くん"
        text="Web入居申込"
        href="/moushikomi-uketsuke/"
      />
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_DK.svg"
        title="電子契約くん"
        text="不動産取引特化の電子契約"
        href="/denshi-keiyaku/"
      />
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_KT.svg"
        title="入居者管理くん"
        text="入居中業務の管理"
        href="/koushin-taikyo/"
      />
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_NSK.svg"
        title="内装工事くんおまかせプラン"
        text="原状回復・修繕工事のWeb管理"
        href="/naiso-kouji-omakase/"
      />
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_RPA.svg"
        title="RPAくん"
        text="データ自動連携"
        href="/rpa-ocr/"
      />
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_IKC.svg"
        title="ITANDI管理クラウド"
        text="基幹システム"
        href="/itandi-kanri-cloud/"
      />
    </>
  );
}

// 仲介SaaSまとめ
function SaaSListChukai() {
  return (
    <>
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_BB.svg"
        title="ITANDI BB"
        text="リアルタイム物件検索（無料）"
        href="/bb-detail/"
      />
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_NC.svg"
        title="nomad cloud[ノマドクラウド]"
        text="顧客管理・追客支援"
        href="/crm/"
      />
      <ServiceMenuCard
        symbolSrc="/assets/Header/Symbol/Symbol_2s.svg"
        title="2秒でブッカク!"
        text="売買物件問い合わせ自動対応"
        href="https://service.propo.co.jp/2sec-bukkaku"
        isExternalLink={true}
      />
    </>
  );
}

// PC版開閉するプロダクト一覧
function HeaderNavPCService(props) {
  return (
    <div className={classnames(style.HeaderNavItemLink, style.ButtonTextPC)}>
      <div className={style.HeaderNavItem}>
        <p className={style.HeaderNavItemText}>{props.text}</p>
        <img className={style.HeaderNavItemIcon} src={props.iconSrc} />
      </div>
      <div className={style.ServiceDropDownMenu}>
        <div className={style.ServiceDropDownMenuContainer}>
          <div
            className={`${style.ServiceDropDownMenuGroup} ${style.ServiceDropDownMenuGroupKanri}`}
            aria-labelledby="ServiceDropDownMenuGroupTitle"
          >
            <h1
              className={style.ServiceDropDownMenuGroupTitle}
              id="ServiceDropDownMenuGroupTitle"
            >
              管理業務SaaS
            </h1>
            <div
              className={`${style.ServiceMenuCardContainer} ${style.ServiceMenuCardKanri}`}
            >
              <SaaSListKanri />
            </div>
          </div>
          <div className={style.ServiceDropDownMenuRight}>
            <div
              className={`${style.ServiceDropDownMenuGroup} ${style.ServiceDropDownMenuGroupChukai}`}
              aria-labelledby="ServiceDropDownMenuGroupTitle"
            >
              <h1
                className={style.ServiceDropDownMenuGroupTitle}
                id="ServiceDropDownMenuGroupTitle"
              >
                仲介業務SaaS
              </h1>
              <div
                className={`${style.ServiceMenuCardContainer} ${style.ServiceMenuCardChukai}`}
              >
                <SaaSListChukai />
              </div>
            </div>
            <div className={style.ServiceDropDownMenuLogin}>
              <div className={style.ServiceDropDownMenuLoginTitle}>
                マイソクから内見の方
                <br />
                入居申込手続はこちら
              </div>
              <LogInButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
// 開閉しないHeaderNavPCItem
function HeaderNavPCItem(props) {
  return (
    <a
      native
      className={classnames(style.HeaderNavItemLink, style.ButtonTextPC)}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={style.HeaderNavItem}>
        <p className={style.HeaderNavItemText}>{props.text}</p>
        <img className={style.HeaderNavItemIcon} src={props.iconSrc} />
      </div>
    </a>
  );
}

// 開閉内のプロダクトのタップエリア
function ServiceMenuCard(props) {
  const isExternalLink = props.isExternalLink || false;
  return (
    <a
      native
      className={style.ServiceMenuCard}
      href={props.href}
      {...(isExternalLink && {
        target: "_blank",
        rel: "noopener noreferrer",
      })}
    >
      <div className={style.ServiceMenuCardWrapper}>
        <img className={style.ServiceMenuCardSymbol} src={props.symbolSrc} />
        <div className={style.ServiceMenuCardDescription}>
          <p className={style.ServiceMenuCardTitle}>{props.title}</p>
          <p className={style.ServiceMenuCardText}>{props.text}</p>
        </div>
      </div>
      {isExternalLink ? (
        <img
          className={style.ServiceMenuCardExternalLinkIcon}
          src="/assets/external_link_icon_gray400.svg"
        />
      ) : (
        <img
          className={style.ServiceMenuCardIcon}
          src="/assets/Header/arrow-right-s-line.svg"
        />
      )}
    </a>
  );
}

function LogInButton() {
  return (
    <Match path="/crm/:remaining_path*">
      {({ matches }) => (
        <a
          native
          className={style.PrimaryButton}
          href={
            matches
              ? `https://${NOMADCLOUD_HOST}/follow_up_client/top`
              : `https://${ITANDI_BB_HOST}/top`
          }
        >
          ログイン
        </a>
      )}
    </Match>
  );
}

function HamburgerMenuItem(props) {
  return (
    <a
      native
      className={style.HamburgerMenuItem}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <p className={style.HamburgerMenuItemText}>{props.text}</p>
      <img
        className={style.ServiceMenuCardIcon}
        src="/assets/Header/external-link-line.svg"
      />
    </a>
  );
}

// ハンバーガーメニュー
function HamburgerMenu() {
  const [isChecked, setIsChecked] = useState(false);
  const [isServiceMenuOpen, setIsServiceMenuOpen] = useState({
    toggle1: true,
    toggle2: true,
  });

  const handleCheckboxChange = (event) => {
    const isMenuChecked = event.target.checked;
    setIsChecked(isMenuChecked);

    // メニューが開かれたときに、常にトグルを開いた状態にする
    if (isMenuChecked) {
      setIsServiceMenuOpen({
        toggle1: true,
        toggle2: true,
      });
    }
  };

  const closeMenu = () => {
    setIsChecked(false);
  };

  return (
    <>
      <input
        id="drawer_input"
        className={style.drawer_hidden}
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <label
        htmlFor="drawer_input"
        id="drawerButton"
        className={style.drawer_open}
      >
        <span />
      </label>

      <div className={style.HamburgerMenu}>
        <div className={style.HamburgerMenuContainer}>
          <div className={style.ServiceHamburgerMenu}>
            <div className={style.ServiceHamburgerMenuTitle}>サービス一覧</div>
            <div className={style.ServiceDropDownMenuContainer}>
              <div className={style.ServiceDropDownMenuGroup}>
                <input
                  type="checkbox"
                  id="toggle1"
                  className={style.toggle}
                  checked={isServiceMenuOpen.toggle1} // 状態に基づいて開閉を制御
                  onChange={() =>
                    setIsServiceMenuOpen((prev) => ({
                      ...prev,
                      toggle1: !prev.toggle1,
                    }))
                  }
                />
                <label
                  className={`${style.ServiceDropDownMenuGroupTitle} ${style.title}`}
                  htmlFor="toggle1"
                >
                  管理業務SaaS
                </label>

                <div
                  className={`${style.ServiceMenuCardContainer} ${style.ServiceMenuCardKanri} ${style.content}`}
                >
                  <SaaSListKanri />
                </div>
              </div>
              <div className={style.ServiceDropDownMenuRight}>
                <div className={style.ServiceDropDownMenuGroup}>
                  <input
                    type="checkbox"
                    id="toggle2"
                    className={style.toggle}
                    checked={isServiceMenuOpen.toggle2} // 状態に基づいて開閉を制御
                    onChange={() =>
                      setIsServiceMenuOpen((prev) => ({
                        ...prev,
                        toggle2: !prev.toggle2,
                      }))
                    }
                  />
                  <label
                    className={`${style.ServiceDropDownMenuGroupTitle} ${style.title}`}
                    htmlFor="toggle2"
                  >
                    仲介業務SaaS
                  </label>
                  <div
                    className={`${style.ServiceMenuCardContainer} ${style.ServiceMenuCardChukai} ${style.content}`}
                  >
                    <SaaSListChukai />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HamburgerMenuItem
            href="https://magazine.itandi.co.jp/%e5%b0%8e%e5%85%a5%e4%ba%8b%e4%be%8b/"
            text="導入事例"
          />
          <HamburgerMenuItem
            href="https://attendee.bizibl.tv/companies/co5zvH9V06ta"
            text="セミナー・イベント"
          />
          <a
            className={`${style.SecondaryButton} ${style.HamburgerMenuItemButton}`}
            href="#contact"
            onClick={closeMenu} // メニューを閉じる
          >
            資料請求・お問い合わせ
          </a>
        </div>
      </div>
    </>
  );
}

const Header = () => (
  <header className={style.Header}>
    <nav className={style.HeaderNav}>
      <a className={style.HeaderLogoContainer} href="/">
        <img
          src={itandibbLogo}
          alt="ITANDI BB"
          className={style.HeaderLogoImage}
        />
      </a>
      <ul className={style.HeaderNavItemContainer}>
        <HeaderNavPCService
          text="サービス一覧"
          iconSrc="/assets/Header/arrow-down-s-line.svg"
        />
        <HeaderNavPCItem
          href="https://magazine.itandi.co.jp/%e5%b0%8e%e5%85%a5%e4%ba%8b%e4%be%8b/"
          text="導入事例"
          iconSrc="/assets/Header/external-link-line.svg"
        />
        <HeaderNavPCItem
          href="https://attendee.bizibl.tv/companies/co5zvH9V06ta"
          text="セミナー・イベント"
          iconSrc="/assets/Header/external-link-line.svg"
        />
      </ul>
    </nav>
    <div className={style.HeaderButtonsContainer}>
      <div class={style.HeaderSecondaryButton}>
        <a native class={style.SecondaryButton} href="#contact">
          <span native class={style.ButtonTextPC}>
            資料請求・
          </span>
          お問い合わせ
        </a>
      </div>
      <LogInButton />
      <HamburgerMenu />
    </div>
  </header>
);

export default Header;
