import { useEffect } from "preact/hooks";

const useAnchorHandle = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash && hash.charAt(0) === "#") {
      const hashId = hash.substring(1);
      const targetContent = document.getElementById(hashId);
      window.scrollTo(0, targetContent.getBoundingClientRect().top);
    }
  }, []);
};

export default useAnchorHandle;
