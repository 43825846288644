import style from "./style.scss";

const CTAAreaNC = () => (
  <div className={style.CTAAreaNCWrapper}>
    <div className={style.CTAAreaNCContainer}>
      <p className={style.CTAAreaNCHeading}>
        <span className={style.CTAAreaNCHeadingStrong}>反響来店率</span>
        を向上させる
        <br />
        顧客管理システム
        <span className={style.CTAAreaNCHeadingSmall}>
          nomad cloud [ノマドクラウド]
        </span>
      </p>
      <a
        native
        className={style.CTAAreaNCPrimaryButtonContainer}
        href="#contact"
      >
        <img
          className={style.CTAAreaNCPrimaryButtonIcon}
          src="/assets/crm/icon/mail_white.svg"
        />
        <p className={style.CTAAreaNCPrimaryButtonText}>
          資料請求・お問い合わせ
        </p>
      </a>
    </div>
  </div>
);

export default CTAAreaNC;
