import style from "./style.scss";

const ZennichiCTAAreaNC = () => (
  <div className={style.CTAAreaNCWrapper}>
    <div className={style.CTAAreaNCContainer}>
      <p className={style.CTAAreaNCHeading}>
        反響来店率が向上する
        <br />
        顧客管理システム
        <br />
        <span className={style.CTAAreaNCHeadingStrong}>
          <span className={style.CTAAreaNCHeadingOrangeText}>特別価格</span>
          で始めませんか？
        </span>
      </p>
      <a
        native
        className={style.CTAAreaNCPrimaryButtonContainer}
        href="#contact"
      >
        <img
          className={style.CTAAreaNCPrimaryButtonIcon}
          src="/assets/crm/icon/mail_white.svg"
        />
        <p className={style.CTAAreaNCPrimaryButtonText}>
          資料請求・お問い合わせ
        </p>
      </a>
    </div>
  </div>
);

export default ZennichiCTAAreaNC;
