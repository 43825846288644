import { h } from "preact";
import ZennichiContactForm from "../../../components/ZennichiContactForm";
import SectionHeading from "../../../components/SectionHeadingWithUnderBar";
import FeatureItem from "../../../components/FeatureItem";
import BenefitItem from "../../../components/BenefitItem";
import style from "./style.scss";

function InquiryButton(props) {
  return (
    <a
      native
      className={style.InquiryButtonContainer}
      target={(props.target === "_blank" && "_blank") || ""}
      rel={(props.target === "_blank" && "noopener noreferrer") || ""}
      href="#contact"
    >
      <p className={style.InquiryButtonText}>資料請求・お問い合わせ</p>
    </a>
  );
}

function PrimaryButton(props) {
  return (
    <a native className={style.PrimaryButtonContainer} href={props.href}>
      <img className={style.PrimaryButtonIcon} src={props.iconSrc} alt="" />
      <p className={style.PrimaryButtonText}>{props.text}</p>
    </a>
  );
}

function CTAArea() {
  return (
    <section className={style.CTAAreaSection}>
      <div className={style.CTAAreaWrapper}>
        <p className={style.CTAAreaCopyText}>
          更新退去手続きの
          <br className={style.CTAAreaCopyTextNewline} />
          Web化を
          <br />
          <span className={style.CTAAreaCopyStorong}>特別価格</span>
          で始めませんか？
        </p>
        <div className={style.CTAAreaButtonContainer}>
          <PrimaryButton
            iconSrc="/assets/koushin_taikyo/download_KTgreen.svg"
            text="資料請求・お問い合わせ"
            href="#contact"
          />
        </div>
      </div>
    </section>
  );
}

function FixedCTA() {
  return (
    <section className={style.FixedCTA}>
      <div className={style.FixedCTAFollow}>
        <p className={style.FixedCTACopyText}>
          全日会員様限定の
          <span className={style.FixedCTACopyStorong}>特別価格</span>
          ではじめませんか？
        </p>
        <PrimaryButton
          iconSrc="/assets/koushin_taikyo/download_KTgreen.svg"
          text="資料請求・お問い合わせ"
          href="#contact"
        />
      </div>
    </section>
  );
}

const ZennichiKoushinTaikyo = () => (
  <>
    <section className={style.FirstViewSection}>
      <ol className={style.BreadcrumbsArea}>
        <li className={style.Breadcrumbs}>
          <a className={style.BreadcrumbsItem} href="/zennichi/itandi-bb">
            TOP
          </a>
        </li>
        <li className={style.Breadcrumbs}>
          <a className={style.BreadcrumbsItem} href="">
            入居者管理くん
          </a>
        </li>
      </ol>
      <div className={style.FirstViewContainer}>
        <div className={style.FirstViewInnerWrapper}>
          <div className={style.FirstViewLogoArea}>
            <div className={style.FirstViewLogoAreaBg01}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30 30L30 0L0 -1.31134e-06C16.5685 -5.87108e-07 30 13.4315 30 30Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={style.FirstViewLogoAreaBg02}>
              <img
                className={style.FirstViewLogoImage}
                src="/assets/service_logo/itandibbplus_KT.svg"
                alt="入居者管理くん"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="167"
                height="45"
                viewBox="0 0 167 45"
                fill="none"
              >
                <path
                  d="M0 0H167.31V24.35C167.31 35.3957 158.356 44.35 147.31 44.35H20C8.9543 44.35 0 35.3957 0 24.35V0Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={style.FirstViewLogoAreaBg03}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.31134e-06 30L0 0L30 -1.31134e-06C13.4315 -5.87108e-07 5.87108e-07 13.4315 1.31134e-06 30Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className={style.FirstViewTextWrapper}>
            <h1 className={style.FirstViewCatchCopy}>
              <span className={style.FirstViewCatchCopyText}>
                入居中の問い合わせ
              </span>
              <br />
              <span className={style.FirstViewCatchCopyText}>
                更新や退去もWebで管理
              </span>
            </h1>
            <div className={style.FirstViewCatchCopySub}>
              <strong className={style.FirstViewCatchCopySubBr}>
                入居者との
              </strong>
              <strong className={style.FirstViewCatchCopySubBr}>
                やり取りを効率化
              </strong>
            </div>
            <InquiryButton />
          </div>
          <div className={style.FirstViewVisualContainer}>
            <img
              className={style.FirstViewVisualImage}
              src="/assets/koushin_taikyo/KTMockup.png"
              alt="入居者管理くん画面イメージ"
            />
          </div>
        </div>
        <div className={style.BgCircle} />
      </div>
      <div className={style.FirstViewAnnotation}>※導入企業様実績より</div>
    </section>
    <section className={style.LogoareaSection}>
      <div className={style.LogoareaWrapper}>
        <img
          alt="入居中業務管理の負担を削減　入居者状況や手続きをWebで管理"
          className={style.LogoareaImagePC}
          src="/assets/koushin_taikyo/Mv_pc_secondary_202407.svg"
        />
        <img
          alt="入居中業務管理の負担を削減　入居者状況や手続きをWebで管理"
          className={style.LogoareaImageSP}
          src="/assets/koushin_taikyo/Mv_sp_secondary_202407.svg"
        />
      </div>
    </section>
    <section className={style.SummarySection}>
      <SectionHeading title="入居者管理くんとは" serviceName="KT" />
      <div className={style.SummarySchematic}>
        <img
          alt
          className={style.SummarySchematicIllust}
          src="/assets/koushin_taikyo/KTDiagram_202407.svg"
        />
      </div>
    </section>
    <section className={style.BenefitSection}>
      <div className={style.BenefitWrapper}>
        <SectionHeading title="入居者管理くんの機能" serviceName="KT" />
        <BenefitItem
          serviceName="KT"
          imgSrc="/assets/koushin_taikyo/KT01.png"
          imgAlt=""
          title="ポイント 1"
          copy={`重要な書類もスムーズに電子契約`}
          description={`更新合意書や退去時の敷金精算書などの重要な書類は、電子契約くんとの連携によって、スムーズに電子契約を行うことが可能です。`}
        />
        <BenefitItem
          serviceName="KT"
          imgSrc="/assets/koushin_taikyo/KT02.png"
          imgAlt=""
          title="ポイント ２"
          copy={`書類の郵送手続き・管理が不要`}
          description={`更新・退去に関する手続きを全てWeb上で行うことができるため、書類を郵送する必要がありません。また書類がなくなることにより、管理業務も効率化することができます。`}
        />
        <BenefitItem
          serviceName="KT"
          imgSrc="/assets/koushin_taikyo/KT03.png"
          imgAlt=""
          title="ポイント ３"
          copy={`更新・退去手続きの進捗をWebで管理`}
          description={`入居者が「更新間近」「退去手続き中」など、状況をWeb上で確認・管理することができます。物件ごとの入居者状況をWebで管理できるため、電話確認など無駄なやりとりをカットできます。`}
        />
      </div>
    </section>
    <CTAArea />
    <section className={style.FeaturesSection}>
      <div className={style.FeaturesWrapper}>
        <div className={style.FeaturesContainer}>
          <FeatureItem
            imgSrc="/assets/koushin_taikyo/KT_validation.svg"
            imgAlt=""
            title={`入力不備を\n自動でチェック`}
            description="Web更新退去・フォーム入力の不備を自動でチェックします。戻し作業が何度も発生することなく、タイムロスを防ぎます。"
          />
          <FeatureItem
            imgSrc="/assets/koushin_taikyo/KT_realtime.svg"
            imgAlt=""
            title={`入居者情報を\nリアルタイムで共有`}
            description={`入居者状況を随時反映。入居者、管理会社、施工会社のどこがボールを持っているのかをすぐ確認できます。`}
          />
          <FeatureItem
            imgSrc="/assets/koushin_taikyo/KT_DK.svg"
            imgAlt=""
            title="手続きが電子契約で完結"
            description="更新・退去手続きともに、電子契約ができるので書類への押印や郵送の必要がありません。"
          />
          <FeatureItem
            imgSrc="/assets/koushin_taikyo/KT_calendar.svg"
            imgAlt=""
            title={`Web上で退去日を調整`}
            description="関係者が多く煩わしかった退去日の日程調整がWebで完結。スムーズに日時を確定できます。"
          />
          <FeatureItem
            imgSrc="/assets/koushin_taikyo/KT_LINE.svg"
            imgAlt=""
            title={`入居者様と\nオンラインでやりとり`}
            description="メール・チャット・SMS、入居者様がやりとりしやすい手段でコミュニケーションできます。電話が苦手な入居者とのやりとりもスムーズに。"
          />
          <FeatureItem
            imgSrc="/assets/koushin_taikyo/KT_cash.svg"
            imgAlt=""
            title={`敷金・修繕費の\n精算のやりとりをWebで完結`}
            description="郵送でやりとりしていた敷金・修繕費の精算のやりとりもWeb上で簡単送付。"
          />
        </div>
      </div>
    </section>
    <section className={style.ContactSection}>
      <div className={style.ContactWrapper} id="contact">
        <SectionHeading title="お問い合わせ・資料請求" serviceName="KT" />
        <ZennichiContactForm />
      </div>
    </section>
    <FixedCTA />
  </>
);

export default ZennichiKoushinTaikyo;
