import style from "./style.scss";
import classnames from "classnames";

function SectionHeadingColord(props) {
  return (
    <h2
      className={classnames(
        style.SectionHeading,
        props.serviceName === "BK" && style.SectionHeadingBK,
        props.serviceName === "NK" && style.SectionHeadingNK,
        props.serviceName === "MU" && style.SectionHeadingMU,
        props.serviceName === "NSK" && style.SectionHeadingNSK
      )}
    >
      {props.title}
    </h2>
  );
}

export default SectionHeadingColord;
