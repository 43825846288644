const functionListIKC = [
  {
    title: "物件管理",
    titleTwoLines: `物件管理`,
    iconOnCirclesSrc:
      "/assets/itandi_kanri_cloud/icon/buildings_on_circles.svg",
  },
  {
    title: `家主管理`,
    titleTwoLines: `家主管理`,
    iconOnCirclesSrc: "/assets/itandi_kanri_cloud/icon/user_on_circles.svg",
  },
  {
    title: `契約書・帳票作成`,
    titleTwoLines: `契約書・\n帳票作成`,
    iconOnCirclesSrc: "/assets/itandi_kanri_cloud/icon/document_on_circles.svg",
  },
  {
    title: `入金自動消込`,
    titleTwoLines: `入金\n自動消込`,
    iconOnCirclesSrc: "/assets/itandi_kanri_cloud/icon/checkbox_on_circles.svg",
  },
  {
    title: `未入金・滞納管理`,
    titleTwoLines: `未入金・\n滞納管理`,
    iconOnCirclesSrc: "/assets/itandi_kanri_cloud/icon/alarm_on_circles.svg",
  },
  {
    title: `オーナー送金`,
    titleTwoLines: `オーナー\n送金`,
    iconOnCirclesSrc:
      "/assets/itandi_kanri_cloud/icon/exchange-cny_on_circles.svg",
  },
  {
    title: `ファームバンキング`,
    titleTwoLines: `ファーム\nバンキング`,
    iconOnCirclesSrc:
      "/assets/itandi_kanri_cloud/icon/send_money_on_circles.svg",
  },
  {
    title: `会計システム連動`,
    titleTwoLines: `会計システム\n連動`,
    iconOnCirclesSrc:
      "/assets/itandi_kanri_cloud/icon/calculator_on_circles.svg",
  },
  {
    title: `Web更新通知`,
    titleTwoLines: `Web\n更新通知`,
    iconOnCirclesSrc: "/assets/itandi_kanri_cloud/icon/refresh_on_circles.svg",
  },
  {
    title: `更新電子契約`,
    titleTwoLines: `更新\n電子契約`,
    iconOnCirclesSrc: "/assets/itandi_kanri_cloud/icon/draft_on_circles.svg",
  },
  {
    title: `Web解約受付`,
    titleTwoLines: `Web\n解約受付`,
    iconOnCirclesSrc: "/assets/itandi_kanri_cloud/icon/logout_on_circles.svg",
  },
  {
    title: `入居者Webページ`,
    titleTwoLines: `入居者\nWebページ`,
    iconOnCirclesSrc: "/assets/itandi_kanri_cloud/icon/contacts_on_circles.svg",
  },
  {
    title: `クレーム管理`,
    titleTwoLines: `クレーム\n管理`,
    iconOnCirclesSrc: "/assets/itandi_kanri_cloud/icon/discuss_on_circles.svg",
  },
  {
    title: `修繕履歴一覧`,
    titleTwoLines: `修繕履歴\n一覧`,
    iconOnCirclesSrc:
      "/assets/itandi_kanri_cloud/icon/paint-brush_on_circles.svg",
  },
  {
    title: `Web立会依頼`,
    titleTwoLines: `Web\n立会依頼`,
    iconOnCirclesSrc: "/assets/itandi_kanri_cloud/icon/tools_on_circles.svg",
  },
  {
    title: `工事工程管理`,
    titleTwoLines: `工事工程\n管理`,
    iconOnCirclesSrc: "/assets/itandi_kanri_cloud/icon/calender_on_circles.svg",
  },
];

export { functionListIKC };
