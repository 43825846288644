import { h } from "preact";
import style from "./style.scss";
import { functionList } from "../../../util/functionList";
import { companiesLogoList } from "../../../util/companiesLogoList";
import ZennichiContactForm from "../../../components/ZennichiContactForm";
import SectionHeading from "../../../components/SectionHeading";
import ZennichiCTAAreaNC from "../../../components/ZennichiCTAAreaNC";

function InquiryButton(props) {
  return (
    <a
      native
      className={style.InquiryButtonContainer}
      target={(props.target === "_blank" && "_blank") || ""}
      rel={(props.target === "_blank" && "noopener noreferrer") || ""}
      href="#contact"
    >
      <p className={style.InquiryButtonText}>資料請求・お問い合わせ</p>
    </a>
  );
}

function PrimaryButton(props) {
  return (
    <a native className={style.PrimaryButtonContainer} href={props.href}>
      <img className={style.PrimaryButtonIcon} src={props.iconSrc} />
      <p className={style.PrimaryButtonText}>{props.text}</p>
    </a>
  );
}

function LinkWithArrow(props) {
  return (
    <a
      native
      className={style.LinkWithArrowContainer}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <p className={style.LinkWithArrowText}>{props.text}</p>
      <img
        className={style.LinkWithArrowIcon}
        src="/assets/crm/icon/line_arrow.svg"
      />
    </a>
  );
}

function BeforeAfterCard(props) {
  return (
    <div
      className={
        (props.backGround === "Binder" &&
          style.BeforeAfterCardContainerBinder) ||
        (props.backGround === "Files" && style.BeforeAfterCardContainerFiles) ||
        style.BeforeAfterCardContainerMegaphone
      }
    >
      <h3 className={style.BeforeAfterCardTitle}>{props.title}</h3>
      <div className={style.BeforeAfterCardParagraphsContainer}>
        <p className={style.BeforeAfterCardSubHeading}>Before</p>
        <p className={style.BeforeAfterCardDescription}>
          {props.descriptionBefore}
        </p>
        <p className={style.BeforeAfterCardSubHeading}>After</p>
        <p className={style.BeforeAfterCardDescription}>
          {props.descriptionAfter}
        </p>
      </div>
    </div>
  );
}

function ReviewCard(props) {
  return (
    <div className={style.ReviewCardContainer}>
      <div className={style.ReviewCardIndex}>{props.index}</div>
      <div className={style.ReviewCardContents}>
        <img className={style.ReviewCardImage} src={props.imgSrc} />
        {props.children}
      </div>
    </div>
  );
}

function ExplanationCard(props) {
  return (
    <div className={style.ExplanationCardWrapper}>
      <img alt="" src={props.imgSrc} className={style.ExplanationCardImage} />
      <h3 className={style.ExplanationCardTitle}>{props.title}</h3>
      <p className={style.ExplanationCardDescription}>{props.description}</p>
    </div>
  );
}

function FunctionLinkCard(props) {
  return (
    <a native href={props.href} className={style.FunctionLinkCardWrapper}>
      <img alt="" src={props.iconSrc} className={style.FunctionLinkCardImage} />
      <h3 className={style.FunctionLinkCardTitle}>{props.title}</h3>
      <p className={style.FunctionLinkCardLinkText}>詳細へ</p>
    </a>
  );
}

function ValueElement(props) {
  return (
    <div className={style.ValueContainer}>
      <div className={style.ValueImageContainer}>
        <img src={props.imgSrc} alt="" className={style.ValueImage} />
      </div>
      <div className={style.ValueDescriptionContainer}>
        <h3 className={style.ValueTitle}>{props.title}</h3>
        <p className={style.ValueCopy}>{props.copy}</p>
        <p className={style.ValueDescription}>{props.description}</p>
      </div>
    </div>
  );
}

function FixedCTA() {
  return (
    <section className={style.FixedCTA}>
      <div className={style.FixedCTAFollow}>
        <p className={style.FixedCTACopyText}>
          全日会員様限定の
          <span className={style.FixedCTACopyStorong}>特別価格</span>
          ではじめませんか？
        </p>
        <PrimaryButton
          iconSrc="/assets/crm/icon/mail_white.svg"
          text="資料請求・お問い合わせ"
          href="#contact"
        />
      </div>
    </section>
  );
}

const ZennichiCRM = () => (
  <>
    <section className={style.FirstViewSection}>
      <ol className={style.BreadcrumbsArea}>
        <li className={style.Breadcrumbs}>
          <a className={style.BreadcrumbsItem} href="/zennichi/itandi-bb">
            TOP
          </a>
        </li>
        <li className={style.Breadcrumbs}>
          <a className={style.BreadcrumbsItem} href="">
            ノマドクラウド
          </a>
        </li>
      </ol>
      <div className={style.FirstViewContainer}>
        <div className={style.FirstViewInnerWrapper}>
          <div className={style.FirstViewLogoArea}>
            <div className={style.FirstViewLogoAreaBg01}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30 30L30 0L0 -1.31134e-06C16.5685 -5.87108e-07 30 13.4315 30 30Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={style.FirstViewLogoAreaBg02}>
              <img
                className={style.FirstViewLogoImage}
                src="/assets/service_logo/itandibbplus_NC.svg"
                alt="ノマドクラウド"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="167"
                height="45"
                viewBox="0 0 167 45"
                fill="none"
              >
                <path
                  d="M0 0H167.31V24.35C167.31 35.3957 158.356 44.35 147.31 44.35H20C8.9543 44.35 0 35.3957 0 24.35V0Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={style.FirstViewLogoAreaBg03}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.31134e-06 30L0 0L30 -1.31134e-06C13.4315 -5.87108e-07 5.87108e-07 13.4315 1.31134e-06 30Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className={style.FirstViewTextWrapper}>
            <h1 className={style.FirstViewCatchCopy}>
              <span className={style.FirstViewCatchCopyText}>
                追客に特化した
              </span>
              <br />
              <span className={style.FirstViewCatchCopyText}>
                顧客管理システム
              </span>
            </h1>
            <div className={style.FirstViewCatchCopySub}>
              売上アップに貢献する
            </div>
            <InquiryButton />
          </div>
          <div className={style.FirstViewVisualContainer}>
            <img
              className={style.FirstViewVisualImageEmblem}
              src="/assets/crm/photo/NCEmblem.png"
              alt="売上貢献満足度No1 サポート体制満足度No1 仲介会社利用率No1"
            />
            <img
              className={style.FirstViewVisualImage}
              src="/assets/crm/photo/NCMockup.png"
              alt="ノマドクラウド画面イメージ"
            />
          </div>
        </div>
        <div className={style.BgCircle} />
      </div>
      <div className={style.FirstViewAnnotation}>
        ※リーシング・マネジメント・コンサルティング株式会社「2024年
        賃貸不動産マーケットのお客様動向調査」（2024/03/04）
        ※株式会社工業市場研究所が5月に発表した「賃貸仲介業者のDX関連調査（賃貸仲介会社に対して電話とFAXを使ってアンケートを実施。架電総数は2,900件、アンケート送付は426件、有効回答145件）」を基に作成。
      </div>
    </section>
    <section className={style.CompaniesSection}>
      <h2 className={style.CompaniesHeading}>
        大手<span className={style.CompaniesHeadingSmall}>から</span>
        地域密着型<span className={style.CompaniesHeadingSmall}>まで</span>
        <br className={style.CompaniesHeadingNewline} />
        <span className={style.CompaniesHeadingColored}>約2,000店舗</span>
        <span className={style.CompaniesHeadingSmall}>で</span>導入
      </h2>
      <div className={style.CompaniesLogoList}>
        {companiesLogoList.map((company) => (
          <div key={company.alt} className={style.CompaniesLogoItem}>
            <img
              alt={company.alt}
              src={company.src}
              className={style.CompaniesLogoImage}
            />
          </div>
        ))}
      </div>
      <div className={style.CompaniesCaption}>
        &#8251;2024年11月時点の導入拠点数です。なお記載の企業は導入企業の一例です
      </div>
    </section>
    <section className={style.InterviewSection}>
      <div className={style.InterviewWrapper}>
        <SectionHeading
          subTitle="USER'S VOICE"
          title="導入企業様の声"
          color="light"
        />
        <div className={style.InterviewContainer}>
          <img
            src="/assets/crm/photo/entetsurealestate_members.png"
            alt="遠州鉄道株式会社様の皆様"
            className={style.InterviewImage}
          />
          <h3 className={style.InterviewTitle}>遠州鉄道株式会社様</h3>
          <p className={style.InterviewDescription}>
            <span className={style.InterviewDescriptionStrong}>
              追客業務を仕組み化でき、かつ自動で行なえる
            </span>
            ことによって、新人が一週間で即戦力になり、他のメンバーと変わらない高い来店率を維持しています。
          </p>
          <p className={style.InterviewDescription}>
            また実際の効果としては、業務の仕組み化・分業化によって
            <span className={style.InterviewDescriptionStrong}>
              反響来店のアポ取得率は1割ほどから約4割にまで上昇
            </span>
            し、店舗によってメール対応への意識にばらつきがある状態を改善できました。
          </p>
          <div className={style.InterviewLink}>
            <LinkWithArrow
              text="インタビュー記事へ"
              href="https://itanzine.itandi.co.jp/interviews/entetsurealestate"
            />
          </div>
        </div>
        <div className={style.InterviewContainer}>
          <img
            src="/assets/crm/photo/ABLAZE_members.png"
            alt="株式会社アブレイズ・コーポレーション様の皆様"
            className={style.InterviewImage}
          />
          <h3 className={style.InterviewTitle}>
            株式会社アブレイズ・コーポレーション様
          </h3>
          <p className={style.InterviewDescription}>
            <span className={style.InterviewDescriptionStrong}>
              業務は飛躍的に効率化しました。追客漏れが防止できるだけでなく、成約後のお客様の掘り起こしが可能
            </span>
            になり、更新時のタイミングでもれなくコンタクトを取ることができるようになったことは、特に効果を実感しています。
          </p>
          <p className={style.InterviewDescription}>
            結果として、リピーター契約も増えてきています。体感的には
            <span className={style.InterviewDescriptionStrong}>
              半分近くのお客様がリピーター
            </span>
            になっているのではないでしょうか。
          </p>
          <div className={style.InterviewLink}>
            <LinkWithArrow
              text="インタビュー記事へ"
              href="https://magazine.itandi.co.jp/casestudy/398/"
            />
          </div>
        </div>
      </div>
    </section>
    <section className={style.ReasonSection}>
      <div className={style.ReasonWrapper}>
        <SectionHeading
          title={`nomad cloudで\n反響来店率が上がる理由`}
          subTitle="REASON"
        />
        <div className={style.ReasonCardContainer}>
          <BeforeAfterCard
            title={`追客業務を\n自動化・効率化`}
            descriptionBefore="各人が１件１件メール対応。反響対応や追客漏れが発生している。"
            descriptionAfter="反響一次対応、物件提案、追客メールを自動化。見込み顧客を来店に繋げる。"
            backGround="Binder"
          />
        </div>
        <div className={style.ReasonCardContainer}>
          <BeforeAfterCard
            title={`反響を即時取込\n即座に対応できる`}
            descriptionBefore="ポータルサイトごとに対応。反響に早く気付けず、他社より遅れがち。"
            descriptionAfter="25以上のポータル反響を即座に通知・対応可能。作業の効率化・対応の漏れを防止。"
            backGround="Files"
          />
        </div>
        <div className={style.ReasonCardContainer}>
          <BeforeAfterCard
            title={`顧客を\n囲い込み`}
            descriptionBefore="反響のあったお客様がポータルサイトから他店に行ってしまう。"
            descriptionAfter="「あなたの店舗とお客様」専用のマイページでやりとり＆物件探しをするから機会損失しない。"
            backGround="Megaphone"
          />
        </div>
      </div>
    </section>
    <ZennichiCTAAreaNC />
    <section className={style.AboutSection}>
      <div className={style.AboutWrapper}>
        <SectionHeading title={`2分でわかる\nnomad cloud`} subTitle="ABOUT" />
        <div className={style.AboutMovieContainer}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/gYlV6opis5A"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
    </section>
    <section className={style.FunctionSection}>
      <div className={style.FunctionWrapper}>
        <SectionHeading
          title={`賃貸仲介に特化した\n充実の機能`}
          subTitle="FUNCTION"
          color="light"
        />
        <div className={style.FunctionList}>
          {functionList.map((functionItem) => (
            <div key={functionItem.title} className={style.FunctionItem}>
              <FunctionLinkCard
                href={`/zennichi/crm/functions#${functionItem.id}`}
                title={functionItem.titleTwoLines}
                iconSrc={functionItem.iconOnCirclesSrc}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className={style.ValueSection}>
      <div className={style.ValueWrapper}>
        <SectionHeading
          subTitle="VALUE"
          title={`nomad cloudだから\nできること`}
        />
        <ValueElement
          imgSrc="/assets/crm/photo/NC_value_pc_202307.png"
          title="反響の自動取り込みと通知"
          copy={`あらゆる反響を取り込み\n即座にスマホアプリに通知`}
          description="複数のポータルサイトに掲載中に反響通知を見逃したり、対応を忘れてしまうことありませんか？ノマドクラウドは反響がシステムに即座に反映・通知されます。また、自動タスク管理機能により、対応漏れが防止でき、代理対応も可能になります。"
        />
        <ValueElement
          imgSrc="/assets/crm/photo/NC_mock_tab_202307.png"
          title="顧客の行動ログ分析"
          copy={`ホットな顧客を見極める`}
          description="お客様が本当にお部屋探しをしているのか、ノマドクラウドなら見極められます。営業担当者様は、顧客のマイページを見ている時間帯やメールの開封履歴を見て、対応方法を検討すれば来店率が上がります。"
        />
        <ValueElement
          imgSrc="/assets/crm/photo/NC_mockup_mobile_line.png"
          title="空室確認問い合わせ反響の一次対応の自動化"
          copy={`空室確認と日程調整は\nもう不要です`}
          description="リアルタイム業者間サイト「ITANDI BB」と連携し、空室状況を自動確認＆日程調整メールを自動送信します。営業時間外の問い合わせに対する機会損失を防ぎます。"
        />
        <ValueElement
          imgSrc="/assets/crm/photo/NC_mockup_mobiles_mypage.png"
          title="顧客専用マイページ"
          copy={`一度来た反響は\n逃さず来店に繋げる`}
          description="せっかく反響が来たのに他店にお客様を取られてしまうことはありませんか？その理由の１つは、お客様が再度ポータルサイトでお部屋探しをしてしまうからです。お客様専用ページ（マイページ）で、お部屋探しをさせることで、ポータルサイトへの離脱を防ぎます。"
        />
      </div>
    </section>
    <section className={style.ReviewSection}>
      <div className={style.ReviewWrapper}>
        <SectionHeading
          title={`顧客満足度アンケートのコメント`}
          subTitle="CUSTOMER REVIEWS"
        />
        <div className={style.ReviewCardWrapper}>
          <ReviewCard
            index="A社"
            imgSrc="/assets/crm/illustration/customer_reviews_image_a.svg"
          >
            <p className={style.ReviewCardSentence}>
              いつもサポートしていただいている担当者様には大変お世話になっており、
              <span className={style.ReviewCardSentenceBold}>
                説明もわかりやすく、大変助かっております。
              </span>
            </p>
          </ReviewCard>
          <ReviewCard
            index="B社"
            imgSrc="/assets/crm/illustration/customer_reviews_image_b.svg"
          >
            <p className={style.ReviewCardSentence}>
              <span className={style.ReviewCardSentenceBold}>
                繁忙期のスケジュール管理、メール返信が劇的に楽になった。
              </span>
              別な担当のお客様の情報共有も楽になった。
            </p>
          </ReviewCard>
        </div>
        <div className={style.ReviewCardWrapper}>
          <ReviewCard
            index="C社"
            imgSrc="/assets/crm/illustration/customer_reviews_image_c.svg"
          >
            <p className={style.ReviewCardSentence}>
              以前のシステムから変わり
              <span className={style.ReviewCardSentenceBold}>
                来店数が伸びた。
              </span>
              ノマドクラウドを利用し、効率化が進み、従業員一同満足しております。
            </p>
          </ReviewCard>
          <ReviewCard
            index="D社"
            imgSrc="/assets/crm/illustration/customer_reviews_image_d.svg"
          >
            <p className={style.ReviewCardSentence}>
              導入の時から特に差し支えなく利用できています。現状、
              <span className={style.ReviewCardSentenceBold}>
                無くてはならないツールとなっています。
              </span>
            </p>
          </ReviewCard>
        </div>
      </div>
    </section>
    <ZennichiCTAAreaNC />
    <section className={style.InstallationSection}>
      <div className={style.InstallationWrapper}>
        <SectionHeading
          title={`経験豊富なチームが\n売上アップをサポート`}
          subTitle="INSTALLATION"
        />
        <div className={style.InstallationCardContainer}>
          <ExplanationCard
            title={`導入レクチャー\n＆安心のサポート`}
            description="パソコンに不慣れな現場でもサポートチームがしっかりレクチャー＆サポートいたします。"
            imgSrc="/assets/crm/illustration/customer_support.svg"
          />
        </div>
        <div className={style.InstallationCardContainer}>
          <ExplanationCard
            title={`定期的な\nフォローアップ`}
            description="業績状況に合わせて、他社様で成功した活用方法や新機能の使い方など定期的にフォローいたします。"
            imgSrc="/assets/crm/illustration/calendar_clock.svg"
          />
        </div>
        <div className={style.InstallationCardContainer}>
          <ExplanationCard
            title={`来店率アップの\nコンサルティング`}
            description="不動産仲介業の経験豊富なスタッフも常駐しており、来店率アップのノウハウを伝授いたします。"
            imgSrc="/assets/crm/illustration/analytics_ui.svg"
          />
        </div>
      </div>
    </section>
    <section className={style.ContactSection} id="contact">
      <div className={style.ContactWrapper} id="contact">
        <SectionHeading title={`資料請求\nお問い合わせ`} subTitle="CONTACT" />
        <ZennichiContactForm />
      </div>
    </section>
    <FixedCTA />
  </>
);

export default ZennichiCRM;
