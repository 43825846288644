import HubspotForm from "../HubspotForm";
import SectionHeading from "../SectionHeading";
import { hubspotFormUrlList } from "../../util/hubspotFormUrlList";

const ContactFormNC = () => {
  const selectedForm = hubspotFormUrlList.find(
    (form) => form.name === "contactNC"
  );

  if (!selectedForm) {
    return <div>指定されたフォームは存在しません。</div>;
  }

  return (
    <div className="container-width">
      <div className="contact-container" id="contact">
        <SectionHeading title={`資料請求\nお問い合わせ`} subTitle="CONTACT" />
        <div className="contact-form">
          <div className="contact-filed-wrapper">
            <HubspotForm
              region={selectedForm.region}
              portalId={selectedForm.portalId}
              formId={selectedForm.formId}
            />
          </div>
          <div className="picked_up_support">
            <div className="contact-filed-wrapper">
              <span className="support-link">
                <a
                  native
                  target="_blank"
                  rel="noopener noreferrer"
                  href={selectedForm.href}
                >
                  お問い合わせフォームが表示されない方はこちら
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFormNC;
