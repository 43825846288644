import { h } from "preact";
import { useEffect, useState, useRef } from "preact/hooks";

const WAIT_MILLISECOND = 2000;

const HubspotForm = ({ portalId, formId }) => {
  const [count, setCount] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    let timeoutId;
    const cleanUpFunction = () => {
      timeoutId && clearTimeout(timeoutId);
    };

    if (count > 20) {
      return cleanUpFunction;
    }

    const script = document.createElement("script");
    let waitAndReloadAndRemoveChild = () => {
      timeoutId = setTimeout(() => setCount((c) => c + 1), WAIT_MILLISECOND);
    };

    try {
      script.src = "//js.hsforms.net/forms/v2.js";
      script.onError = waitAndReloadAndRemoveChild;
      document.body.appendChild(script);

      window.hbspt.forms.create({
        portalId,
        formId,
        target: `#${ref.current.id}`,
      });
    } catch (e) {
      waitAndReloadAndRemoveChild();
    }

    return cleanUpFunction;
  }, [count, portalId, formId]);

  return <div id="hubspotForm" ref={ref} />;
};

export default HubspotForm;
