const hubspotFormUrlList = [
  {
    name: "contactDefault",
    region: "na1",
    portalId: "6532992",
    formId: "31940e3b-4dad-4a26-b917-8b8064a530a9",
    href: "https://share.hsforms.com/1MZQOO02tSia5F4uAZKUwqQ3w0w0",
    description: "ITANDI BB資料請求・お問い合わせ｜2021/04/06から",
  },
  {
    name: "contactBBDetail",
    region: "na1",
    portalId: "6532992",
    formId: "756637fd-552e-45a0-9256-cd8c3794aab1",
    href: "https://share.hsforms.com/1dWY3_VUuRaCSVs2MN5SqsQ3w0w0",
    description: "業者間サイト掲載獲得LP用",
  },
  {
    name: "contactNC",
    region: "na1",
    portalId: "6532992",
    formId: "cb352313-afad-4bab-bafc-29cfb8494e7b",
    href: "https://share.hsforms.com/1yzUjE6-tS6u6_CnPuElOew3w0w0",
    description: "ノマドクラウド問い合わせフォーム",
  },
  {
    name: "contactNSKOmakase",
    region: "na1",
    portalId: "6532992",
    formId: "37d292ba-275d-4c9c-9245-85f1627af717",
    href: "https://share.hsforms.com/1N9KSuiddTJySRYXxYnr3Fw3w0w0",
    description: "ITANDI BB_NSK_資料請求・お問い合わせ",
  },
  {
    name: "contactIKC",
    region: "na1",
    portalId: "6532992",
    formId: "29a26b49-d40e-483d-8bd9-1b093fc881fc",
    href: "https://share.hsforms.com/1KaJrSdQOSD2L2RsJP8iB_A3w0w0",
    description: "ITANDI BB_IKC_資料請求・お問い合わせ",
  },
  {
    name: "contactZennichi",
    region: "na1",
    portalId: "6532992",
    formId: "10a3e5d3-3dd4-4d8f-82e6-ebd657d11ef9",
    href: "https://share.hsforms.com/1EKPl0z3UTY-C5uvWV9Ee-Q3w0w0",
    description: "全日専用お問い合わせフォーム",
  },
  {
    name: "contactZennichiIKS",
    region: "na1",
    portalId: "6532992",
    formId: "efed3368-26b7-451a-bc5b-45a739893853",
    href: "https://share.hsforms.com/17-0zaCa3RRq8W0WnOYk4Uw3w0w0",
    description: "2023/08/02_ラビーネットBB一括出稿システム用",
  },
  {
    name: "contactBaibai",
    region: "na1",
    portalId: "23154961",
    formId: "2686eef9-d4a9-4e79-87d9-2b9e06d95e21",
    href: "https://share.hsforms.com/1Jobu-dSpTnmH2SueBtleIQdsahd",
    description: "2秒でブッカク！専用フォーム（Housmart）",
  },
];

export { hubspotFormUrlList };
