import style from "./style.scss";
import classnames from "classnames";

function BenefitItem(props) {
  return (
    <div className={style.BenefitItemWrapper}>
      <div className={style.BenefitItemImageContainer}>
        <img
          className={style.BenefitItemImage}
          src={props.imgSrc}
          alt={props.imgAlt}
        />
      </div>
      <div className={style.BenefitItemContentContainer}>
        <h3
          className={classnames(
            style.BenefitItemHeading,
            props.serviceName === "BK" && style.BenefitItemHeadingBK,
            props.serviceName === "NK" && style.BenefitItemHeadingNK,
            props.serviceName === "MU" && style.BenefitItemHeadingMU,
            props.serviceName === "KT" && style.BenefitItemHeadingKT,
            props.serviceName === "NSK" && style.BenefitItemHeadingNSK,
            props.serviceName === "NSKOmakase" &&
              style.BenefitItemHeadingNSKOmakase
          )}
        >
          {props.title}
        </h3>
        <h4 className={style.BenefitItemCopy}>{props.copy}</h4>
        <p className={style.BenefitItemDescription}>{props.description}</p>
      </div>
    </div>
  );
}

export default BenefitItem;
