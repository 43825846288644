import style from "./style.scss";

function StepItem(props) {
  return (
    <div className={style.StepItemWrapper}>
      <div className={style.StepItemImageContainer}>
        <img
          className={style.StepItemImage}
          src={props.imgSrc}
          alt={props.imgAlt}
        />
      </div>
      <h3 className={style.StepItemTitle}>{props.title}</h3>
      <p className={style.StepItemDescription}>{props.description}</p>
    </div>
  );
}

export default StepItem;
