import style from "./style.scss";

function AchievementVoiceItem(props) {
  return (
    <div className={style.AchievementVoiceItemWrapper}>
      <div className={style.AchievementVoiceItemImageContainer}>
        <img
          className={style.AchievementVoiceItemImage}
          src={props.imgSrc}
          alt={props.imgAlt}
        />
      </div>
      <p className={style.AchievementVoiceItemDescription}>
        {props.description}
      </p>
    </div>
  );
}

export default AchievementVoiceItem;
