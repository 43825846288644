import style from "./style.scss";

function FeatureItem(props) {
  return (
    <div className={style.FeatureItemWrapper}>
      <div className={style.FeatureItemHeadingContainer}>
        <img
          className={style.FeatureItemHeadingImage}
          src={props.imgSrc}
          alt={props.imgAlt}
        />
        <h3 className={style.FeatureItemHeadingTitle}>{props.title}</h3>
      </div>
      <p className={style.FeatureItemDescription}>{props.description}</p>
    </div>
  );
}

export default FeatureItem;
