import style from "./style.scss";
import classnames from "classnames";

function SectionHeadingWithUnderBar(props) {
  return (
    <div className={style.SectionHeadingWithUnderBarWrapper}>
      <h2
        className={classnames(
          style.SectionHeadingWithUnderBarText,
          props.serviceName === "BK" && style.SectionHeadingWithUnderBarTextBK,
          props.serviceName === "NK" && style.SectionHeadingWithUnderBarTextNK,
          props.serviceName === "MU" && style.SectionHeadingWithUnderBarTextMU,
          props.serviceName === "KT" && style.SectionHeadingWithUnderBarTextKT,
          props.serviceName === "NSK" && style.SectionHeadingWithUnderBarTextNSK
        )}
      >
        {props.title}
      </h2>
    </div>
  );
}

export default SectionHeadingWithUnderBar;
