import { h } from "preact";
import ZennichiContactForm from "../../../components/ZennichiContactForm";
import SectionHeading from "../../../components/SectionHeadingColord";
import FeatureItem from "../../../components/FeatureItem";
import BenefitItem from "../../../components/BenefitItem";
import AchievementVoiceItem from "../../../components/AchievementVoiceItem";
import AchievementTableItem from "../../../components/AchievementTableItem";
import OptionCard from "../../../components/OptionCard";
import style from "./style.scss";

function InquiryButton(props) {
  return (
    <a
      native
      className={style.InquiryButtonContainer}
      target={(props.target === "_blank" && "_blank") || ""}
      rel={(props.target === "_blank" && "noopener noreferrer") || ""}
      href="#contact"
    >
      <p className={style.InquiryButtonText}>資料請求・お問い合わせ</p>
    </a>
  );
}

function PrimaryButton(props) {
  return (
    <a native className={style.PrimaryButtonContainer} href={props.href}>
      <img className={style.PrimaryButtonIcon} src={props.iconSrc} alt="" />
      <p className={style.PrimaryButtonText}>{props.text}</p>
    </a>
  );
}

function CTAArea() {
  return (
    <section className={style.CTAAreaSection}>
      <div className={style.CTAAreaWrapper}>
        <p className={style.CTAAreaCopyText}>
          内見予約の
          <br className={style.CTAAreaCopyTextNewline} />
          web受付システムを
          <br />
          <span className={style.CTAAreaCopyStorong}>特別価格</span>
          で始めませんか？
        </p>
        <div className={style.CTAAreaButtonContainer}>
          <PrimaryButton
            iconSrc="/assets/naiken_yoyaku/download_NKblue.svg"
            text="資料請求・お問い合わせ"
            href="#contact"
          />
        </div>
      </div>
    </section>
  );
}

function FixedCTA() {
  return (
    <section className={style.FixedCTA}>
      <div className={style.FixedCTAFollow}>
        <p className={style.FixedCTACopyText}>
          全日会員様限定の
          <span className={style.FixedCTACopyStorong}>特別価格</span>
          ではじめませんか？
        </p>
        <PrimaryButton
          iconSrc="/assets/naiken_yoyaku/download_NKblue.svg"
          text="資料請求・お問い合わせ"
          href="#contact"
        />
      </div>
    </section>
  );
}

const ZennichiNaikenYoyaku = () => (
  <>
    <section className={style.FirstViewSection}>
      <ol className={style.BreadcrumbsArea}>
        <li className={style.Breadcrumbs}>
          <a className={style.BreadcrumbsItem} href="/zennichi/itandi-bb">
            TOP
          </a>
        </li>
        <li className={style.Breadcrumbs}>
          <a className={style.BreadcrumbsItem} href="">
            内見予約くん
          </a>
        </li>
      </ol>
      <div className={style.FirstViewContainer}>
        <div className={style.FirstViewInnerWrapper}>
          <div className={style.FirstViewLogoArea}>
            <div className={style.FirstViewLogoAreaBg01}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30 30L30 0L0 -1.31134e-06C16.5685 -5.87108e-07 30 13.4315 30 30Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={style.FirstViewLogoAreaBg02}>
              <img
                className={style.FirstViewLogoImage}
                src="/assets/service_logo/itandibbplus_NK.svg"
                alt="内見予約くん"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="167"
                height="45"
                viewBox="0 0 167 45"
                fill="none"
              >
                <path
                  d="M0 0H167.31V24.35C167.31 35.3957 158.356 44.35 147.31 44.35H20C8.9543 44.35 0 35.3957 0 24.35V0Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={style.FirstViewLogoAreaBg03}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.31134e-06 30L0 0L30 -1.31134e-06C13.4315 -5.87108e-07 5.87108e-07 13.4315 1.31134e-06 30Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className={style.FirstViewTextWrapper}>
            <h1 className={style.FirstViewCatchCopy}>
              <span className={style.FirstViewCatchCopyText}>
                <strong className={style.FirstViewCatchCopySubBr}>
                  オンラインで
                </strong>
                <strong className={style.FirstViewCatchCopySubBr}>
                  いつでも
                </strong>
              </span>
              <br />
              <span className={style.FirstViewCatchCopyText}>
                内見予約を自動受付
              </span>
            </h1>
            <div className={style.FirstViewCatchCopySub}>
              <strong className={style.FirstViewCatchCopySubBr}>
                内見の機会損失を
              </strong>
              <strong className={style.FirstViewCatchCopySubBr}>ゼロに</strong>
            </div>
            <div className={style.FirstViewButtonWrapper}>
              <InquiryButton />
              <a
                native
                className={style.ReservationButtonContainer}
                rel="nofollow"
                href={`https://${ITANDI_BB_HOST}/top`}
              >
                <p className={style.ReservationButtonText}>
                  内見予約したい
                  <br />
                  仲介会社様はこちら
                </p>
              </a>
            </div>
          </div>
          <div className={style.FirstViewVisualContainer}>
            <img
              className={style.FirstViewVisualImageEmblem}
              src="/assets/naiken_yoyaku/NKEmblem.png"
              alt="仲介会社利用率No1"
            />
            <img
              className={style.FirstViewVisualImage}
              src="/assets/naiken_yoyaku/NKMockup.png"
              alt="内見予約くん画面イメージ"
            />
          </div>
        </div>
        <div className={style.BgCircle} />
      </div>
      <div className={style.FirstViewAnnotation}>
        ※リーシング・マネジメント・コンサルティング株式会社「2024年
        賃貸不動産マーケットのお客様動向調査」（2024/03/04）
      </div>
    </section>
    <section className={style.LogoAndCopySection}>
      <div className={style.LogoAndCopyWrapper}>
        <img
          className={style.LogoAndCopyImagePC}
          src="/assets/naiken_yoyaku/mv_pcsecondary.png"
          alt="Mv pcsecondary"
        />
        <img
          className={style.LogoAndCopyImageSP}
          src="/assets/naiken_yoyaku/mv_spsecondary.png"
          alt="Mv spsecondary"
        />
      </div>
    </section>
    <section className={style.AbstractSection}>
      <img
        className={style.AbstractImage}
        src="/assets/naiken_yoyaku/naiken_conseptual.png"
        alt="Bukkakun conseptual"
      />
    </section>
    <section className={style.FeaturesSection}>
      <div className={style.FeaturesWrapper}>
        <SectionHeading title="機能詳細" serviceName="NK" />
        <div className={style.FeaturesContainer}>
          <FeatureItem
            imgSrc="/assets/naiken_yoyaku/detail-icon01.png"
            imgAlt=""
            title="いつでも内見予約"
            description="24時間、365日、オンラインでいつでも内見予約ができます。内見受付に対応していた人件費や時間を削減します。"
          />
          <FeatureItem
            imgSrc="/assets/naiken_yoyaku/detail-icon02.png"
            imgAlt=""
            title="内見予約管理"
            description={`内見予約状況を管理します。\nいつ・どの仲介会社から何件内見予約が入ったのかがカレンダーから確認できます。`}
          />
          <FeatureItem
            imgSrc="/assets/naiken_yoyaku/detail-icon03.png"
            imgAlt=""
            title="不正業者チェック"
            description="仲介会社様が不動産免許を持っているか電話番号から自動チェック。安心の上、鍵情報を伝えます。"
          />
          <FeatureItem
            imgSrc="/assets/naiken_yoyaku/detail-icon04.png"
            imgAlt=""
            title="自動物件配信"
            description="物件一覧画面に、フリーレントやADなどのキャンペーン物件情報を掲載し反響数をUPさせます。"
          />
          <FeatureItem
            imgSrc="/assets/naiken_yoyaku/detail-icon05.png"
            imgAlt=""
            title="図面/申込書ダウンロード"
            description="仲介会社様はWeb上から手軽に物件の図面や申込書をダウンロードできます。"
          />
        </div>
      </div>
    </section>
    <section className={style.MovieSection}>
      <div className={style.MovieContainer}>
        <iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="true"
          frameBorder={0}
          height={315}
          src="https://www.youtube.com/embed/p_o-UlRUzcE"
          width={560}
        />
      </div>
    </section>
    <section className={style.BenefitSection}>
      <div className={style.BenefitWrapper}>
        <BenefitItem
          serviceName="NK"
          imgSrc="/assets/naiken_yoyaku/performance01.png"
          imgAlt=""
          title="導入効果 1"
          copy={`内見予約数が15%もUP`}
          description={`業務外の時間帯でもオンライン上で内見予約を受付けることができるので、今まで取り逃がしていた内見予約を全て受付することができます。`}
        />
        <BenefitItem
          serviceName="NK"
          imgSrc="/assets/naiken_yoyaku/performance02.png"
          imgAlt=""
          title="導入効果 ２"
          copy={`スタッフの電話や\nFAXするコストが半減`}
          description={`内見受付にスタッフが対応する必要がありません。今まで電話やFAXでやりとりしていた人件費や通信費をカットすることができます。`}
        />
        <BenefitItem
          serviceName="NK"
          imgSrc="/assets/naiken_yoyaku/performance03.png"
          imgAlt=""
          title="導入効果 ３"
          copy={`オーナーの満足度がUP`}
          description={`今までわかりづらかった電話反響を計測しレポートします。\nいつ・どの物件に何件内見が入ったのかを集計・レポートできるため、オーナーの満足度を向上することができます。`}
        />
      </div>
    </section>
    <CTAArea />
    <section className={style.AchievementSection}>
      <div className={style.AchievementWrapper}>
        <SectionHeading title="導入企業様の声" serviceName="NK" />
        <div className={style.AchievementContainer}>
          <div className={style.AchievementVoiceList}>
            <AchievementVoiceItem
              imgSrc="/assets/naiken_yoyaku/voice02.png"
              imgAlt=""
              description={`物件確認の応対や内見予約などの業務を効率化。\nオーナー様報告に注力し満足度アップを実現。\n―株式会社メイクスマネジメント様（ぶっかくん・内見予約くん導入）`}
            />
            <a
              native
              className={style.AchievementVoicesLink}
              href="https://www.itandi.co.jp/casestudies"
              target="_blank"
              rel="noopener noreferrer"
            >
              ▶︎ 導入事例をすべて見る
            </a>
          </div>
          <div className={style.AchievementTableContainer}>
            <div className={style.AchievementTableHeaderContainer}>
              <h3 className={style.AchievementTableHeaderTitle}>実績</h3>
              <p className={style.AchievementTableHeaderCaption}>
                ※2023年1月末時点
              </p>
            </div>
            <AchievementTableItem
              heading="内見予約数"
              contentNumber="20秒に1件"
              contentUnit=""
              serviceName="NK"
            />
            <AchievementTableItem
              heading="仲介利用拠点"
              contentUnitSmall="約"
              contentNumber="51,000"
              contentUnit="店舗"
              serviceName="NK"
            />
          </div>
        </div>
      </div>
    </section>
    <section className={style.AchievementCompanySection}>
      <div className={style.AchievementCompanyWrapper}>
        <SectionHeading title="導入実績" serviceName="NK" />
        <ul className={style.AchievementCompanyList}>
          <li className={style.AchievementCompanyItem}>
            <img
              src="/assets/naiken_yoyaku/cl_logo03.png"
              alt="メイクス"
              className={style.AchievementCompanyLogo}
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              src="/assets/naiken_yoyaku/cl_logo04.png"
              alt="アートアベニュー"
              className={style.AchievementCompanyLogo}
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              src="/assets/companies_logo/daikyo_logo_kumi_daikyo_anabuki_realestate_big_yoko.png"
              alt="大京穴吹不動産"
              className={style.AchievementCompanyLogo}
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              src="/assets/naiken_yoyaku/cl_logo06.png"
              alt="マルイホームサービス"
              className={style.AchievementCompanyLogo}
            />
          </li>
        </ul>
      </div>
    </section>
    <section className={style.OptionSection}>
      <div className={style.OptionWrapper}>
        <SectionHeading
          title="合わせて使うと便利なオプション"
          serviceName="NK"
        />
        <div className={style.OptionContainer}>
          <OptionCard
            href="/zennichi/moushikomi-uketsuke/"
            logoSrc="/assets/bukkaku/moshikomiuketsukekun.png"
            productName="申込受付くん"
            title="申込書提出から審査完了までをスムーズに"
            description="入居申込書のWeb受付システム。申込書提出から審査までのやり取りの時短と効率化を実現。"
            tagKanri={true}
            tagChukai={false}
          />
          <OptionCard
            href="/zennichi/bukkaku/"
            logoSrc="/assets/naiken_yoyaku/bukkakun.png"
            productName="ぶっかくん"
            title="物確対応を自動化して業務効率アップ"
            description="物件確認の自動応答システム。定休日も時間外も物確電話に24時間365日対応。"
            tagKanri={true}
            tagChukai={false}
          />
        </div>
      </div>
    </section>
    <section className={style.ContactSection}>
      <div className={style.ContactWrapper} id="contact">
        <SectionHeading title="資料請求" serviceName="NK" />
        <ZennichiContactForm />
      </div>
    </section>
    <FixedCTA />
  </>
);

export default ZennichiNaikenYoyaku;
