import style from "./style.scss";

function OptionCard(props) {
  return (
    <a native className={style.OptionCardWrapper} href={props.href}>
      <div className={style.OptionCardImageContainer}>
        <img alt="" className={style.OptionCardImage} src={props.logoSrc} />
        <h3 className={style.OptionCardProductName}>{props.productName}</h3>
      </div>
      <div className={style.OptionCardContent}>
        <p className={style.OptionCardTitle}>{props.title}</p>
        <p className={style.OptionCardDescription}>{props.description}</p>
        <div className={style.OptionCardTagList}>
          {props.tagKanri && (
            <p className={style.OptionCardTag}>管理会社様向け</p>
          )}
          {props.tagChukai && (
            <p className={style.OptionCardTag}>仲介会社様向け</p>
          )}
          <p className={style.OptionCardTag}>オプション</p>
          <p className={style.OptionCardTextLink}>詳細 ▶︎</p>
        </div>
      </div>
    </a>
  );
}

export default OptionCard;
