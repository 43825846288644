import HubspotForm from "../HubspotForm";
import { hubspotFormUrlList } from "../../util/hubspotFormUrlList";

const ZennichiContactFormIKS = () => {
  const selectedForm = hubspotFormUrlList.find(
    (form) => form.name === "contactZennichiIKS"
  );

  if (!selectedForm) {
    return <div>指定されたフォームは存在しません。</div>;
  }

  return (
    <>
      <div className="contact-filed-wrapper">
        <HubspotForm
          region={selectedForm.region}
          portalId={selectedForm.portalId}
          formId={selectedForm.formId}
        />
      </div>
      <div className="picked_up_support">
        <div className="contact-filed-wrapper">
          <span className="support-link">
            <a
              native
              target="_blank"
              rel="noopener noreferrer"
              href={selectedForm.href}
            >
              お問い合わせフォームが表示されない方はこちら
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default ZennichiContactFormIKS;
