import style from "./style.scss";

function InterviewCard(props) {
  return (
    <a
      native
      className={style.InterviewCardContainer}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={style.InterviewCardImageContaner}>
        <img
          className={style.InterviewCardImage}
          src={props.imgSrc}
          alt={props.imgAlt}
        />
      </div>
      <p className={style.InterviewCardTitle}>{props.title}</p>
    </a>
  );
}

export default InterviewCard;
