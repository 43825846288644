const functionList = [
  {
    title: "ポータル反響の自動取込",
    titleTwoLines: `ポータル反響の\n自動取込`,
    description: `25以上の各賃貸ポータルサイト（SUUMO、HOME'sなど）や自社サイトの反響を自動で取り込み、ノマドクラウド一つで対応できます。\n\n時間がかかる入居希望者様の仕分けや重複チェックもノマドクラウドなら自動完了。反響時に入居希望者様に自動返信メールを送信することも可能です。`,
    iconSrc: "/assets/crm/icon/download_cloud.svg",
    iconOnCirclesSrc: "/assets/crm/icon/download_cloud_on_circles.svg",
    imageSrc: "/assets/crm/illustration/hankyo_torikomi.png",
    id: "import",
  },
  {
    title: `顧客一元管理`,
    titleTwoLines: `顧客一元管理`,
    description: `不動産業に特化したシンプルでわかりやすい顧客管理です。\n\n入居希望者様のお問い合わせ内容や反響物件、タスク管理とも紐づいており、顧客管理に手間取りません。`,
    iconSrc: "/assets/crm/icon/user_file.svg",
    iconOnCirclesSrc: "/assets/crm/icon/user_file_on_circles.svg",
    imageSrc: "/assets/crm/illustration/kokyaku_kanri.png",
    id: "customer-manage",
  },
  {
    title: `反響対応もれ管理`,
    titleTwoLines: `反響対応もれ\n管理`,
    description: `入居希望者様からの反響やメッセージ、来店予約があった場合、未対応タスクとして確認ができます。\n\n営業担当者様へのタスクの割り当てや、やることの一括登録も可能ですのでタスク漏れの心配が軽減されます。`,
    iconSrc: "/assets/crm/icon/check_boxes.svg",
    iconOnCirclesSrc: "/assets/crm/icon/check_boxes_on_circles.svg",
    imageSrc: "/assets/crm/illustration/task.png",
    id: "task-manage",
  },
  {
    title: `自動追客を簡単カスタマイズ`,
    titleTwoLines: `自動追客を\n簡単カスタマイズ`,
    description: `入居希望者様の希望にあった物件を自動的に抽出し、物件提案を行います（物件自動提案機能）。\n\nまた、オプションをご利用いただくことで、送信するメッセージの内容、タイミング、回数などを自由に何パターンも設定できます。入居希望者様の状況に応じて、柔軟に追客メッセージを変更することも可能です。`,
    iconSrc: "/assets/crm/icon/send_mail.svg",
    iconOnCirclesSrc: "/assets/crm/icon/send_mail_on_circles.svg",
    imageSrc: "/assets/crm/illustration/zidou_tsuikyaku.png",
    id: "proposal",
  },
  {
    title: `自動物確即レス機能`,
    titleTwoLines: `自動物確\n即レス機能`,
    description: `入居希望者様からの空室確認お問い合わせに対し、リアルタイム業者間サイトITANDI BBで空室情報・内見予約状況を自動確認して回答します。\n\n内見可能な物件の場合、入居希望者様がご自身で内見予約でき、反響来店予約率向上につながります。`,
    iconSrc: "/assets/crm/icon/reply.svg",
    iconOnCirclesSrc: "/assets/crm/icon/reply_on_circles.svg",
    imageSrc: "/assets/crm/illustration/response_mail.png",
    id: "reply",
    tag: "オプション",
    position: "bottom",
  },
  {
    title: `来店・内見予約カレンダー`,
    titleTwoLines: `来店・内見予約\nカレンダー`,
    description: `追客中の入居希望者様がご自身で来店・内見・ウェブ面談の予約を行うことができます。\n\n店舗の営業時間外でも予約を受け付けることができるため、機会損失を減らして、反響来店率アップが見込めます。`,
    iconSrc: "/assets/crm/icon/checked_calendar.svg",
    iconOnCirclesSrc: "/assets/crm/icon/checked_calendar_on_circles.svg",
    imageSrc: "/assets/crm/illustration/booking_calendar.png",
    id: "calendar",
    position: "bottom",
  },
  {
    title: `連絡ツールの一括管理`,
    titleTwoLines: `連絡ツールの\n一括管理`,
    description: `メール・LINE・SMS・チャットなど、様々な連絡ツールでのやりとりをノマドクラウドでひとまとめに管理・返信できます。\n\n各種連絡ツールの確認し忘れや、担当者様が不在時の対応漏れなどのリスクを回避し、一つのツールで効率よく接客できます。`,
    iconSrc: "/assets/crm/icon/chat_setting.svg",
    iconOnCirclesSrc: "/assets/crm/icon/chat_setting_on_circles.svg",
    imageSrc: "/assets/crm/illustration/communication_tool.png",
    id: "communication",
  },
  {
    title: `LINE連携`,
    titleTwoLines: `LINE連携`,
    description: `「LINE」のトーク画面上にて、入居希望者様への自動物件提案やメッセージ送信などのコミュニケーションが行えるオプション機能です。\n\nメッセージアプリの中でも利用者数が多いLINEプラットフォームを利用することで、返信率、来店率向上が見込めます。`,
    iconSrc: "/assets/crm/icon/line.svg",
    iconOnCirclesSrc: "/assets/crm/icon/line_on_circles.svg",
    imageSrc: "/assets/crm/illustration/line.png",
    id: "line",
    tag: "オプション",
    position: "bottom",
  },
  {
    title: `SMS（ショートメッセージ）`,
    titleTwoLines: `SMS\n（ショートメッセージ）`,
    description: `携帯電話番号宛てにショートメッセージ(SMS)を送信します。\n\n返信率や来店率向上に繫がるだけでなく、申込手続の業務効率化等にも有効です。メールが当たり前になりすぎた時代だからこそ、ショートメッセージが効果を発揮します。`,
    iconSrc: "/assets/crm/icon/chats.svg",
    iconOnCirclesSrc: "/assets/crm/icon/chats_on_circles.svg",
    imageSrc: "/assets/crm/illustration/sms.png",
    id: "sms",
    tag: "オプション",
    position: "bottom",
  },
  {
    title: `メッセージの既読確認`,
    titleTwoLines: `メッセージの\n既読確認`,
    description: `入居希望者様それぞれに送信したメッセージの未読・既読の確認や一斉に送信したダイレクトメールの開封率・クリック率などを確認できます。\n\n分析結果が可視化され、データに基づいた施策を計画・実行することができます。`,
    iconSrc: "/assets/crm/icon/checked_chat.svg",
    iconOnCirclesSrc: "/assets/crm/icon/checked_chat_on_circles.svg",
    imageSrc: "/assets/crm/illustration/maisoku_in_mail.png",
    id: "message-check",
  },
  {
    title: `ビデオ通話`,
    titleTwoLines: `ビデオ通話`,
    description: `ワンクリックで、入居希望者様へビデオ通話参加のためのURLを送信し、簡単にビデオ通話を開始できます。\n\n画面共有機能を使い、画面上に物件資料などを表示して、入居希望者様に見せながら接客できます。また、入居希望者様の代わりにお部屋に行き、部屋の様子を映しながら紹介する「オンライン内見」の実施も可能です。`,
    iconSrc: "/assets/crm/icon/video.svg",
    iconOnCirclesSrc: "/assets/crm/icon/video_on_circles.svg",
    imageSrc: "/assets/crm/illustration/video.png",
    id: "video",
    position: "bottom",
  },
  {
    title: `顧客行動ログの見える化`,
    titleTwoLines: `顧客行動ログの\n見える化`,
    description: `提案物件や送信メールの既読/未読、それらを閲覧した時間帯がグラフで可視化されているため、時間帯を絞って効率的に入居希望者様にアプローチすることが可能です。\n\nまた、現在オンラインになっている入居希望者様が分かるため、繫がりやすい入居希望者様に即座にアプローチすることができます。`,
    iconSrc: "/assets/crm/icon/footprint.svg",
    iconOnCirclesSrc: "/assets/crm/icon/footprint_on_circles.svg",
    imageSrc: "/assets/crm/illustration/kokyaku_log.png",
    id: "log",
  },
  {
    title: `顧客専用マイページ`,
    titleTwoLines: `顧客専用\nマイページ`,
    description: `入居希望者様1人1人に専用のマイページを自動生成します。マイページでは、入居希望者様ご自身での物件検索、過去に提案された物件の確認、来店のご予約等が行えます。\n\nまた、店舗に気軽に連絡ができるチャット画面も用意されています。`,
    iconSrc: "/assets/crm/icon/heart_in_home.svg",
    iconOnCirclesSrc: "/assets/crm/icon/heart_in_home_on_circles.svg",
    imageSrc: "/assets/crm/illustration/my_page.png",
    id: "mypage",
    position: "bottom",
  },
  {
    title: `物件検索・提案`,
    titleTwoLines: `物件検索・提案`,
    description: `リアルタイム業者間サイトITANDI BBに掲載されている物件および、自社物件サイトから取り込んだ物件情報を検索、そのまま入居希望者様に送信・提案することができます。`,
    iconSrc: "/assets/crm/icon/buildings.svg",
    iconOnCirclesSrc: "/assets/crm/icon/buildings_on_circles.svg",
    imageSrc: "/assets/crm/illustration/maisoku_with_woman.png",
    id: "search",
  },
  {
    title: `AIチャット`,
    titleTwoLines: `AIチャット`,
    description: `入居希望者様からのお問い合わせの一部をAI（人工知能）が返信するオプション機能です。\n\n導入会社様のお問い合わせ内容を学習させることで、入居希望者様からのお問い合わせの約4割を自動で返信し、スピーディーな返信と業務タスクの大幅な改善が可能になります。`,
    iconSrc: "/assets/crm/icon/dotts_in_chat.svg",
    iconOnCirclesSrc: "/assets/crm/icon/dotts_in_chat_on_circles.svg",
    id: "ai-chat",
    imageSrc: "/assets/crm/illustration/aichat.png",
    tag: "オプション",
    position: "bottom",
  },
  {
    title: `スマートフォンアプリ`,
    titleTwoLines: `スマートフォン\nアプリ`,
    description: `ノマドクラウドはPCやスマホ、様々なデバイスに対応。Web版はもちろん、スマホアプリからも顧客管理が可能です。\n\n外出先でもさくさくタスク管理や入居希望者様とのメッセージが行え、担当者様にメッセージが届くとプッシュ通知でお知らせをします。`,
    iconSrc: "/assets/crm/icon/mobile.svg",
    iconOnCirclesSrc: "/assets/crm/icon/mobile_on_circles.svg",
    imageSrc: "/assets/crm/illustration/mobile_app.png",
    id: "mobile-app",
  },
];

export { functionList };
