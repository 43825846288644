const companiesLogoList = [
  {
    alt: "日本エイジェント",
    src: "/assets/companies_logo/nihonagent.png",
  },
  {
    alt: "木下の賃貸",
    src: "/assets/companies_logo/kinoshitanochintai.png",
  },
  {
    alt: "S-FIT",
    src: "/assets/companies_logo/sfit.png",
  },
  {
    alt: "遠鉄の不動産",
    src: "/assets/companies_logo/entetsurealestate.png",
  },
  {
    alt: "株式会社シイ・アイ・シー",
    src: "/assets/companies_logo/cic.png",
  },
  { alt: "N・ASSET", src: "/assets/companies_logo/nasset.png" },
  {
    alt: "アブレイズコーポレーション",
    src: "/assets/companies_logo/ablazecoporation.png",
  },
  {
    alt: "アルプス住宅サービス株式会社",
    src: "/assets/companies_logo/alpsjyutakuservice.png",
  },
  {
    alt: "松屋住まいるパートナーズ",
    src: "/assets/companies_logo/matsuyasmilepartners.png",
  },
  {
    alt: "福徳不動産",
    src: "/assets/companies_logo/hukutokurealestate.png",
  },
  {
    alt: "平和住宅情報センター",
    src: "/assets/companies_logo/heiwajyuutaku.png",
  },
  {
    alt: "シティ・ハウジング",
    src: "/assets/companies_logo/cityhousing.png",
  },
  {
    alt: "サンエイホーム",
    src: "/assets/companies_logo/saneihome.png",
  },
];

export { companiesLogoList };
