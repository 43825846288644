import style from "./style.scss";
import classnames from "classnames";

function SectionHeading(props) {
  return (
    <div className={style.SectionHeadingContainer}>
      <p
        className={classnames(
          style.SectionHeadingSubTitle,
          props.color === "light" && style.SectionHeadingSubTitleLight
        )}
      >
        {props.subTitle}
      </p>
      <h2
        className={classnames(
          style.SectionHeadingTitle,
          props.color === "light" && style.SectionHeadingTitleLight
        )}
      >
        {props.title}
      </h2>
    </div>
  );
}

export default SectionHeading;
