import style from "./style.scss";
import classnames from "classnames";

function RectangleButtonWithIcon(props) {
  return (
    <a
      native
      className={classnames(
        style.RectangleButtonWithIconWrapper,
        props.colorStyle === "NKWhiteFill" &&
          style.RectangleButtonWithIconWrapperNKWhiteFill,
        props.colorStyle === "NKWhiteLine" &&
          style.RectangleButtonWithIconWrapperNKWhiteLine,
        props.colorStyle === "MUWhiteFill" &&
          style.RectangleButtonWithIconWrapperMUWhiteFill,
        props.colorStyle === "MUWhiteLine" &&
          style.RectangleButtonWithIconWrapperMUWhiteLine,
        props.colorStyle === "KTWhiteFill" &&
          style.RectangleButtonWithIconWrapperKTWhiteFill,
        props.colorStyle === "NSKWhiteFill" &&
          style.RectangleButtonWithIconWrapperNSKWhiteFill,
        props.colorStyle === "NSKWhiteLine" &&
          style.RectangleButtonWithIconWrapperNSKWhiteLine
      )}
      href={props.href}
    >
      <figure
        className={classnames(
          style.RectangleButtonWithIconIconContainer,
          props.colorStyle === "NKWhiteFill" &&
            style.RectangleButtonWithIconIconContainerNKWhiteFill,
          props.colorStyle === "NKWhiteLine" &&
            style.RectangleButtonWithIconIconContainerNKWhiteLine,
          props.colorStyle === "MUWhiteFill" &&
            style.RectangleButtonWithIconIconContainerMUWhiteFill,
          props.colorStyle === "MUWhiteLine" &&
            style.RectangleButtonWithIconIconContainerMUWhiteLine,
          props.colorStyle === "KTWhiteFill" &&
            style.RectangleButtonWithIconIconContainerKTWhiteFill,
          props.colorStyle === "NSKWhiteFill" &&
            style.RectangleButtonWithIconIconContainerNSKWhiteFill,
          props.colorStyle === "NSKWhiteLine" &&
            style.RectangleButtonWithIconIconContainerNSKWhiteLine
        )}
      >
        <img
          className={style.RectangleButtonWithIconIcon}
          src={props.iconSrc}
          alt={props.iconAlt}
        />
      </figure>
      <p className={style.RectangleButtonWithIconText}>{props.text}</p>
    </a>
  );
}

export default RectangleButtonWithIcon;
