import { h } from "preact";
import ContactFormNC from "../../../components/ContactFormNC";
import style from "./style.scss";
import { functionList } from "../../../util/functionList";
import classnames from "classnames";
import CTAAreaNC from "../../../components/CTAAreaNC";

function FunctionCardListSection(props) {
  return (
    <section className={style.FunctionCardListSection}>
      {props.functionList.map((functionItem) => (
        <div
          key={functionItem.title}
          className={style.FunctionCardWrapper}
          id={functionItem.id}
        >
          <div className={style.FunctionCardContainer}>
            <div
              className={classnames(
                style.FunctionCardImageContainer,
                functionItem.position === "bottom" &&
                  style.FunctionCardImageContainerSetBottom
              )}
            >
              <img
                src={functionItem.imageSrc}
                alt=""
                className={style.FunctionCardImage}
              />
            </div>
            <div className={style.FunctionCardDescriptionContainer}>
              <h3 className={style.FunctionCardTitle}>{functionItem.title}</h3>
              {functionItem.tag && (
                <p className={style.FunctionCardTag}>{functionItem.tag}</p>
              )}
              <p className={style.FunctionCardDescription}>
                {functionItem.description}
              </p>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}

const Functions = () => (
  <div className={style.FunctionsContentWrapper}>
    <nav className={style.BreadcrumbsWrapper}>
      <ol className={style.BreadcrumbsList}>
        <li className={style.BreadcrumbsItem}>
          <a native href="/crm" className={style.BreadcrumbsItemLink}>
            不動産賃貸仲介業の営業支援システム ノマドクラウド
          </a>
        </li>
        <li className={style.BreadcrumbsItem}>機能一覧</li>
      </ol>
    </nav>
    <section className={style.FunctionsIndexSection}>
      <div className={style.FunctionsIndexWrapper}>
        <div className={style.SectionHeadingContainer}>
          <p className={style.SectionHeadingSubTitle}>FUNCTION</p>
          <h1 className={style.SectionHeadingTitle}>
            ノマドクラウドの
            <br className={style.SectionHeadingTitleNewline} />
            機能一覧
          </h1>
        </div>
      </div>
    </section>
    <div className={style.MainAndAsideContainer}>
      <div className={style.MainAndAsideAsideContainer}>
        <div className={style.MainAndAsideAsideItem}>
          {functionList.map((functionItem) => (
            <a
              native
              key={functionItem.title}
              href={`#${functionItem.id}`}
              className={style.FunctionAnchorLinkContainer}
            >
              <img
                src={functionItem.iconSrc}
                alt=""
                className={style.FunctionAnchorLinkIcon}
              />
              <p className={style.FunctionAnchorLinkText}>
                {functionItem.title}
              </p>
            </a>
          ))}
        </div>
      </div>
      <div className={style.MainAndAsideMainContainer}>
        <FunctionCardListSection functionList={functionList.slice(0, 6)} />
        <section className={style.CTAAreaNCSection}>
          <div className={style.CTAAreaNCWrapper}>
            <CTAAreaNC />
          </div>
        </section>
        <FunctionCardListSection functionList={functionList.slice(6, 11)} />
        <section className={style.CTAAreaNCSection}>
          <div className={style.CTAAreaNCWrapper}>
            <CTAAreaNC />
          </div>
        </section>
        <FunctionCardListSection functionList={functionList.slice(11, 17)} />
      </div>
    </div>
    <section className={style.ContactSection} id="contact">
      <ContactFormNC />
    </section>
  </div>
);

export default Functions;
