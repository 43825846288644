import { h } from "preact";
import style from "./style.scss";

const ZennichiFooter = () => (
  <footer class={style.Footer}>
    <ul class={style.FooterNav}>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/zennichi/itandi-bb">
          TOP
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a
          native
          class={style.FooterNavItemLink}
          href="/zennichi/ikkatsu-syukko/"
        >
          一括出稿
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/zennichi/bukkaku/">
          物件確認応答
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a
          native
          class={style.FooterNavItemLink}
          href="/zennichi/naiken-yoyaku/"
        >
          内見予約
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a
          native
          class={style.FooterNavItemLink}
          href="/zennichi/moushikomi-uketsuke/"
        >
          申込受付
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a
          native
          class={style.FooterNavItemLink}
          href="/zennichi/denshi-keiyaku/"
        >
          電子契約
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a
          native
          class={style.FooterNavItemLink}
          href="/zennichi/koushin-taikyo/"
        >
          入居者管理
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a
          native
          class={style.FooterNavItemLink}
          href="/zennichi/naiso-kouji-omakase/"
        >
          内装工事
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a
          native
          class={style.FooterNavItemLink}
          href="/zennichi/itandi-kanri-cloud/"
        >
          賃貸管理
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/zennichi/crm/">
          顧客管理
        </a>
      </li>
    </ul>
    <ul class={style.FooterNav}>
      <li class={style.FooterNavItem}>
        <a
          native
          class={style.FooterNavItemLink}
          href="https://www.itandi.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          会社案内
        </a>
      </li>
      <li class={style.FooterNavItem}>
        <a native class={style.FooterNavItemLink} href="/privacy">
          プライバシーポリシー
        </a>
      </li>
    </ul>
    <p class={style.Copyright}>© ITANDI, Inc. All rights reserved.</p>
  </footer>
);

export default ZennichiFooter;
